var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"canvas-container",on:{"wheel":function($event){$event.preventDefault();return _vm.handleWheel.apply(null, arguments)},"mousedown":_vm.handleCanvasMouseDown,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp,"mouseleave":_vm.handleMouseUp}},[_c('div',{staticClass:"grid-background"}),_c('div',{staticClass:"canvas",style:({
         transform: `scale(${_vm.scale}) translate(${_vm.offsetX}px, ${_vm.offsetY}px)`,
         transformOrigin: '0 0'
       })},[_c('div',{staticClass:"node form-node",style:({left: _vm.formNode.x + 'px', top: _vm.formNode.y + 'px'}),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDragNode($event, 'form')}}},[_c('div',{staticClass:"node-header"},[_c('div',{staticClass:"node-title"},[_vm._v("表单模型: "+_vm._s(_vm.form.name))]),_c('div',{staticClass:"node-controls"},[_c('button',{staticClass:"node-collapse-btn",on:{"click":function($event){$event.stopPropagation();_vm.formNode.collapsed = !_vm.formNode.collapsed}}},[_vm._v(" "+_vm._s(_vm.formNode.collapsed ? '+' : '-')+" ")])])]),(!_vm.formNode.collapsed)?_c('div',{staticClass:"node-content"},_vm._l((_vm.controls),function(control){return _c('div',{key:control.id,staticClass:"node-item",class:{
               'node-item-selected': _vm.isSelected(control), 
               'node-item-binded': _vm.isControlBinded(control)
             },attrs:{"data-id":control.id}},[_c('div',{staticClass:"node-item-content"},[_c('div',{staticClass:"node-item-title"},[_vm._v(_vm._s(control.name))]),_c('div',{staticClass:"node-item-details"},[_c('span',{staticClass:"node-item-type"},[_vm._v(_vm._s(control.control))])])]),_c('div',{staticClass:"connector-point connector-right",on:{"mousedown":function($event){$event.stopPropagation();return _vm.startConnection($event, control, 'control')}}})])}),0):_vm._e()]),_c('div',{staticClass:"node data-node",style:({left: _vm.dataNode.x + 'px', top: _vm.dataNode.y + 'px'}),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDragNode($event, 'data')}}},[_c('div',{staticClass:"node-header"},[_c('div',{staticClass:"node-title"},[_vm._v("数据模型: "+_vm._s(_vm.model.name))]),_c('div',{staticClass:"node-controls"},[_c('button',{staticClass:"node-collapse-btn",on:{"click":function($event){$event.stopPropagation();_vm.dataNode.collapsed = !_vm.dataNode.collapsed}}},[_vm._v(" "+_vm._s(_vm.dataNode.collapsed ? '+' : '-')+" ")])])]),(!_vm.dataNode.collapsed)?_c('div',{staticClass:"node-content"},_vm._l((_vm.model.fields),function(field){return _c('div',{key:field.key,staticClass:"node-item",class:{
               'node-item-selected': _vm.isSelected(field), 
               'node-item-readonly': !field.editable,
               'node-item-binded': _vm.isFieldBinded(field)
             },attrs:{"data-key":field.key}},[_c('div',{staticClass:"connector-point connector-left",on:{"mousedown":function($event){$event.stopPropagation();return _vm.startConnection($event, field, 'field')}}}),_c('div',{staticClass:"node-item-content"},[_c('div',{staticClass:"node-item-title"},[_vm._v(_vm._s(field.key))]),_c('div',{staticClass:"node-item-details"},[_c('span',{staticClass:"node-item-type"},[_vm._v(_vm._s(field.type))]),(!field.editable)?_c('span',{staticClass:"readonly-badge"},[_vm._v("只读")]):_vm._e(),(field.editable && _vm.dragging && _vm.dragSource && _vm.dragSource.type === 'control')?_c('div',{staticClass:"connector-compatibility"},[(_vm.isCompatible(_vm.dragSource.item, field))?_c('span',{staticClass:"compatibility-indicator compatible"},[_vm._v("兼容")]):_c('span',{staticClass:"compatibility-indicator incompatible"},[_vm._v("不推荐")])]):_vm._e()])]),(_vm.isFieldBinded(field))?_c('Button',{staticClass:"delete-connection-btn",attrs:{"title":"删除连接","icon":"md-close"},on:{"click":function($event){$event.stopPropagation();return _vm.removeConnectionByField(field)}}}):_vm._e()],1)}),0):_vm._e()])]),_c('div',{staticClass:"zoom-controls"},[_c('button',{staticClass:"zoom-btn",on:{"click":_vm.zoomIn}},[_vm._v("+")]),_c('button',{staticClass:"zoom-btn",on:{"click":_vm.zoomOut}},[_vm._v("-")]),_c('button',{staticClass:"zoom-btn",on:{"click":_vm.resetZoom}},[_c('BaseIcon',{attrs:{"icon":"md-refresh"}})],1)]),_c('div',{staticClass:"canvas-info"},[_vm._v(" 缩放: "+_vm._s(Math.round(_vm.scale * 100))+"% | 连接数: "+_vm._s(_vm.bindingData.length)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }