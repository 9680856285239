<template>
  <div style="overflow:hidden;width:100%;height:100%;position:relative;">
    <div class="flex-wrap " style="height:50px;padding:0 10px;">
      <h2>数据库备份管理</h2>
      <BaseIcon icon="md-refresh" style="margin-left:10px"  @click="getData"/>
    </div>
    <div style="position:relative;height:calc(100% - 60px);width:100%;padding:0 10px;">
        <BaseTable
            ref="backupsTable"
            :columns="columns"
            :loading="loading"
            border
            :data="items"
        />
    </div>
  </div>
</template>

<script>

export default {
  name: 'BackupsPage',
  data() {
    return {
      loading: false,
      columns: [
        {
            type:'index',
          title: 'ID',
          key: 'id',
          width: 80,
          sortable: true
        },
        {
          title: '创建时间',
          key: 'created_at',
          type:"time",
          width: 180,
          sortable: true,
          option:{
            type:'date'
          }
        },
        {
          title: '备份名称',
          key: 'name',
          minWidth: 200,
          sortable: true,
          render:(h,params)=>{
            return h('div',{class:'flex-wrap',style:"padding:10px"},params.row.name)
          }
        },
        
        {
          title: '操作',
          slot: 'action',
          width: 150,
          fixed: 'right',
          render:(h,{row})=>{
            let btnDownload = h("Button",{props:{type:'primary',size:'small'},on:{
                click:()=>{
                    this.handleDownload(row)
                }
            }},"下载")
            return h('div',{class:"flex-wrap flex-center"},[btnDownload])
          }
        }
      ],
      items:[]
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    getData(){
        this.loading = true
        this.$api.get('admin/backups?order=created_at&desc=1').then(res=>{
            this.items =res.data.data
        }).finally(()=>{
            this.loading = false
        })
    },
    formatDate(date) {
      if (!date) return '未知时间'
      const d = new Date(date)
      return d.toLocaleString()
    },
    
    handleRefresh() {
      this.$refs.backupsTable.refresh()
    },
    
    async handleDownload(row) {
      try {
        row.downloading = true
        await this.DowloadWithName(row.url, row.name)
        this.$Message.success('下载成功')
      } catch (error) {
        console.error('下载失败:', error)
        this.$Message.error('下载失败')
      } finally {
        row.downloading = false
      }
    },
    
    handleDelete(row) {
      Modal.confirm({
        title: '确认删除',
        content: `确定要删除备份文件 "${row.name}" 吗？`,
        onOk: async () => {
          try {
            row.deleting = true
            await this.$api.delete(`/admin/backups/${row.id}`)
            this.$Message.success('删除成功')
            this.handleRefresh()
          } catch (error) {
            console.error('删除失败:', error)
            this.$Message.error('删除失败')
          } finally {
            row.deleting = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.backups-page {
  padding: 20px;
  
  .page-header {
    margin-bottom: 20px;
    
    h2 {
      margin: 0;
      font-size: 24px;
      color: #17233d;
    }
  }
  
  .backups-card {
    /deep/ .ivu-card-body {
      padding: 20px;
    }
  }
  
  /deep/ .ivu-btn {
    margin-right: 8px;
    
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
