<template>
  <div class="dep-select-pro">
    <Select 
      v-model="selectedDep" 
      filterable 
      clearable 
      placeholder="请选择部门"
      :disabled="disabled"
      @on-change="handleDepChange"
      style="width: 100%"
    >
      <Option 
        v-for="dep in filteredDeps" 
        :value="dep.id" 
        :key="dep.id"
        :label="dep.name"
      >
        <span v-if="dep.top" style="color: #2d8cf0; font-weight: bold">
          {{ dep.name }} <Tag color="blue" size="small">事业部</Tag>
        </span>
        <span v-else>
          <span v-for="n in getDepLevel(dep)" :key="n" style="margin-right: 4px; color: #999;">— </span>
          {{ dep.name }}
        </span>
      </Option>
    </Select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DepSelectPro',
  props: {
    value: {
      type: String,
      default: ''
    },
    topId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    parentOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedDep: this.value || ''
    }
  },
  computed: {
    ...mapGetters({
      allDeps: 'session/deps'
    }),
    filteredDeps() {
      if (!this.allDeps) return []
      
      // 过滤掉ignore=1的部门
      const validDeps = this.allDeps.filter(dep => dep.ignore !== 1)
      
      // 如果只需要父部门
      if (this.parentOnly) {
        return validDeps.filter(dep => dep.parent_id === null || dep.parent_id === '')
      }
      
      // 构建部门树结构以便排序
      const depsMap = {}
      validDeps.forEach(dep => {
        depsMap[dep.id] = { ...dep, children: [] }
      })
      
      const rootDeps = []
      validDeps.forEach(dep => {
        if (!dep.parent_id) {
          rootDeps.push(depsMap[dep.id])
        } else if (depsMap[dep.parent_id]) {
          depsMap[dep.parent_id].children.push(depsMap[dep.id])
        } else {
          rootDeps.push(depsMap[dep.id])
        }
      })
      
      // 展平树结构为排序后的数组
      return this.flattenDepTree(rootDeps)
    }
  },
  watch: {
    value(newVal) {
      this.selectedDep = newVal
    }
  },
  mounted() {
    this.fetchDeps()
    
    // 如果已有选中部门但没有top-id，则尝试查找对应的事业部ID
    if (this.value && !this.topId) {
      this.updateTopDep(this.value)
    }
  },
  methods: {
    fetchDeps() {
      this.$store.dispatch('session/getDeps')
    },
    handleDepChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
      
      // 更新事业部ID
      this.updateTopDep(value)
    },
    updateTopDep(depId) {
      if (!depId) {
        this.$emit('update:topId', '')
        return
      }
      
      // 查找选择的部门
      const dep = this.findDep(depId)
      if (!dep) return
      
      // 如果本身就是事业部
      if (dep.top === 1) {
        this.$emit('update:topId', dep.id)
        return
      }
      
      // 向上查找事业部
      const topDep = this.findTopDep(dep)
      this.$emit('update:topId', topDep ? topDep.id : '')
    },
    findDep(id) {
      return this.allDeps.find(d => d.id === id)
    },
    findTopDep(dep) {
      if (!dep) return null
      
      // 如果自己是事业部
      if (dep.top === 1) return dep
      
      // 如果没有父部门
      if (!dep.parent_id) return null
      
      // 递归查找父部门
      const parentDep = this.findDep(dep.parent_id)
      return this.findTopDep(parentDep)
    },
    flattenDepTree(tree) {
      let result = []
      
      // 先排序顶级部门，使事业部排在前面
      tree.sort((a, b) => {
        // 先按事业部排序
        if (a.top === 1 && b.top !== 1) return -1
        if (a.top !== 1 && b.top === 1) return 1
        
        // 再按名称排序
        return a.name.localeCompare(b.name)
      })
      
      tree.forEach(dep => {
        result.push(dep)
        if (dep.children && dep.children.length > 0) {
          // 递归添加子部门，并按名称排序
          dep.children.sort((a, b) => a.name.localeCompare(b.name))
          result = result.concat(this.flattenDepTree(dep.children))
        }
      })
      
      return result
    },
    getDepLevel(dep) {
      // 计算部门的层级，用于缩进显示
      if (!dep.parent_id) return 0
      
      let level = 0
      let currentId = dep.parent_id
      
      while (currentId) {
        level++
        const parent = this.findDep(currentId)
        if (!parent) break
        currentId = parent.parent_id
      }
      
      return level
    }
  }
}
</script>

<style scoped>
.dep-select-pro {
  width: 100%;
}
</style>
