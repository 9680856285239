<template>
  <div class="container flow-canvas">
    
    <DndPanel @drag-start="onDragStart" style="position:fixed;left:80px;top:140px;z-index:100" />
    <ActionPanel @on-click="handleActionEvent" style="position:fixed;left:calc(50% - 200px);top:60px;z-index:100" />
    <PropertyPanel 
      :element="selected || flow" 
      :prop="elementProps"
      :env="{forms}"
      @on-event="handleEvent"
      @on-change-prop="handleChangeProperty"
      style="position:fixed;right:80px;top:140px;z-index:100" />
    <FormPanel 
      v-model="forms"
      style="position:fixed;bottom:50px;;z-index:100"
      :option="{flowId:flow.id}" 
    /> 
   
    <div class="l-canvas" ref="canvas"> </div>
    
    <BaseLoadingModal :value="loading" title="正在处理" />

    <Modal v-model="showInstance" title="流程测试" :width="1200" footer-hide>
      <FlowInstance 
        :flow="flowDef"
        :version="flow"
        test 
      />
    </Modal>

  </div>
</template>

<script>
import LogicFlow from '@logicflow/core';
import {Snapshot,SelectionSelect,MiniMap,Menu  } from '@logicflow/extension'
import DndPanel from './components/DndPanel.vue';
import ActionPanel from './components/ActionPanel.vue'
import FormPanel from './components/FormPanel.vue'
import mixinResponsable from '@/mixins/responsable';
import '@logicflow/core/dist/index.css';
import '@logicflow/extension/dist/index.css';
import ApprovalNode from './components/nodes/approval.js'
import StartNode from './components/nodes/start.js'
import EndNode from './components/nodes/finish.js'
import EventNode from './components/nodes/event.js'
import PropertyPanel from './components/PropertyPanel'
import mixinState from '@/mixins/page_state'
import CarbonNode from './components/nodes/carbon'
import FlowInstance from '../instance.vue'
import SubmitEdge from './components/edges/submit'
import {cloneDeep} from 'lodash'
import UTIL from '@/utils'
LogicFlow.use(Snapshot)
LogicFlow.use(SelectionSelect)
LogicFlow.use(MiniMap)
LogicFlow.use(Menu)
export default {
  data() {
    return {
      lf: null,
      lf_data:null,
      nodes: [],
      loading:false,
      showInstance:false,
      selected:null,
      flowData:{},
      showFlow:{},
      forms:[]
    };
  },
  props:{
    'flow-def':{
      type:Object,
      default:()=>({})
    },
    flow:{
      type:Object,
      default:()=>({})
    }
  },
  mixins: [mixinResponsable,mixinState],
  components: { DndPanel , ActionPanel,PropertyPanel,FormPanel,FlowInstance},
  computed:{
    flow_id(){
      return this.$route.params.id
    },
    elementProps(){
      if(!this.selected){
        return {
          tools:[],
          attrs:[{
            key:'name',
            label:'名称',
            control:'Text'
          },{
            key:'version',
            label:'版本号',
            control:'Text'
          },{
            key:'description',
            label:'版本说明',
            control:'Textarea'
          },{
            key:'active',
            label:'设为默认',
            control:'Check'
          }]
        }
      }else{
        let model= this.lf.getModelById(this.selected.id)
        let props = [] 
        let attrs = [{
          key:'type',
          label:'类型',
          
          control:'Text'
        },{
          key:'x',
          label:'x',
          disabled:true,
          control:'Text'
        },{
          key:'y',
          label:'y',
          disabled:true,
          control:'Text'
        }]
        let tools = [{
            name:'删除',
            icon:'md-trash',
            title:'删除(Backspace)',
            key:'delete'
        }] 
        if(model.getTools)
          tools = tools.concat(...(model.getTools()  || []))
        if(model.getProps)
          props = model.getProps() || []

        if(model.BaseType == 'edge'){
          props = props.concat([{
            key:'type',
            label:'类型',
            control:'Select',
            option:{
              options:[{value:'submit',label:'提交'},{value:'reject',label:'退回'}]
            },
            group:'审批'
          },{
            key:'condition',
            label:'条件',
            control:'Condition',
            group:'审批'
          }])
        }
        console.log(model,props)
        if(model.BaseType == 'node'){
          props = props.concat([{
            key:'view_id',
            label:'视图',
            control:"Select",
            group:'视图',
            option:{
              options:this.forms.map(v=>({label:v.name,value:v.id}))
            }
          },{
            key:'acl',
            label:'访问配置',
            control:"PermissionSelect",
            group:'视图',
            editable:this.selected.properties.view_id,
            option:{
              mode:'form',
              form_id:this.selected.properties.view_id
            }
          }])
        }
        
        return {attrs,tools,props}
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
    
      this.Init();
      
    })
    
  },
  methods: {
    __beforeSavePageState(){
      if(this.lf && this.flow_id == 'create'){
        localStorage.setItem('lf_content',JSON.stringify(this.lf.getGraphData()))
      }
    },
    __afterRestorePageState(){
      let data = localStorage.getItem('lf_content')
      if(data && this.flow_id == 'create'){
        
        this.lf_data = JSON.parse(data)
        localStorage.removeItem('lf_content')
      }
      
    },
    Init() {
      if (this.lf) {
        delete this.lf;
      }
      
      let container = this.$refs.canvas;
      if(!container)
        return
      this.$nextTick(() => {
        this.lf = new LogicFlow({
          container,
          width: container.clientWidth,
          height: container.clientHeight,
          background: { color: '#F0F0F0' },
          grid: { type: 'dot', size: 20 },
          textEdit: true,
          isSilentMode: false,
          edgeType: 'approval-edge',
          stopScrollGraph:true,
          animation:true,
          snapline: true,
          stopMoveGraph: false,
          adjustEdgeStartAndEnd:true,
          history:true,
          keyboard: {
            enabled: true,
          },
          outline:true,
          style: { rect: { radius: 6 } },
          
        });

        this.lf.register(StartNode);
        this.lf.register(ApprovalNode);
        this.lf.register(EndNode);
        this.lf.register(EventNode)
        this.lf.register(CarbonNode)

        this.lf.register(SubmitEdge)

        this.Render();
        this.lf.translateCenter();
        this.lf.on('blank:click',e=>{
          this.selected = null
        })
        this.lf.on('history:change',()=>{
          this.UpdateLocal()
          if(this.selected)
            this.selected = this.lf.getDataById(this.selected.id)
        })
        this.lf.extension.miniMap.show()
        this.lf.on('element:click', (event) => {
        const { data } = event;
        console.log(data)
        this.selected = data

        // 菜单
        this.lf.addMenuConfig({
          nodeMenu:[],
          edgeMenu:[]
        })
      });

          this.$emit('on-refresh')
      });
    },
    Render(data) {
      if(data){
        this.lf.render(data)
      }else if(this.lf_data){
        this.lf.render(this.lf_data)
        this.lf_data = null
      }else{
        this.lf.render();
      }
      
    },
    handleChangeProperty({key,value}){
      if(this.selected == null)
        return
      let elementId = this.selected.id
      let elementModel = this.lf.getModelById(elementId);
      console.log('change-prop:',elementModel,key,value)
      elementModel.properties = { 
        ...elementModel.properties,
        [key]: value 
      };
      console.log(elementModel)
      // 触发 LogicFlow 内部更新（自动刷新视图）
      elementModel.updateAttributes({}); // ✅ 空对象即可触发
    },
    handleEvent(key){
      if(key == 'delete'){
        if(!this.selected)
          return
        if(Array.isArray(this.selected)){

        }else{
          this.lf.deleteElement(this.selected.id)
        }
       
        this.selected = null
      }
    },
    onResize(w, h) {
      if(this.lf){
        
        this.lf_data = this.lf.getGraphData()
        this.Init();
      }
    },
    onDragStart(type) {
      this.lf.dnd.startDrag({ type });
    },
    setLoading(enable){
      this.loading = enable
    },
    UpdateLocal(){
      let res = this.lf.getGraphData()
      this.showFlow = {...this.flow,...res}
    },
    Save(){
      let res = this.lf.getGraphData()
      
      this.$emit('on-submit',{...this.flow,...res})
    },
    CreateTest(){
      this.showInstance = true
    },
    handleActionEvent(type){  
      const actionMap = {
        download:()=>{
          this.lf.getSnapshot('flow')
        },
        'selection':()=>{this.lf.openSelectionSelect()},
        'arrow':()=>{this.lf.closeSelectionSelect()},
        undo:()=>{this.lf.undo()},
        redo:()=>{this.lf.redo()},
        'zoom-in':()=>{this.lf.zoom(true);},
        'zoom-out':()=>{this.lf.zoom(false);},
        'expand':()=>{this.lf.fitView(300,300)},
        'reset':()=>{this.$emit('on-refresh')}, 
        'save':this.Save,
        'test':this.CreateTest,
      }
      let func = actionMap[type]
      if(func){
        func()
      }
    }
  },
};
</script>

<style lang="less">
.flow-canvas {
  background: rgb(240, 249, 255);
  position: relative;
}

.l-canvas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #ffe;
  overflow: hidden;
}


.lf-tool-overlay{
  .lf-menu{
    .lf-menu-item{
      color:#333;
    }
  }
}

.lf-text-input{
  color:#333;
}

</style>
