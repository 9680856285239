<template>
  <div class="container flex-line cm-page">
    <div class="cm-page-left"  style="background:var(--bg3)" >
        <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen"  />
    </div>
    <div ref="container" class="cm-page-right">
      <div class="cm-page-inner">
         <CmProjectHeader :data="project" class="cm-box" />
       <div class="cm-box" ref="basic">
         
          <CmProjectInfo ref="seg-basic" :data="project" @on-update="getData" />
        <div v-if="project.status == '已结束'" icon="md-checkmark" color="var(--success)"
                style="position:absolute;right:260px;top:-5px;padding:5px;border:3px solid var(--success);border-radius:50%;opacity:0.9;font-weight:bold;color:var(--success);transform:rotateZ(-25deg);padding:20px;font-size:30px;z-index:10;" 
                :size="30"
              >项目已结束</div>
       </div>
       <div  ref="analysis" class="cm-box">
       <CmProjectAnalysis ref="seg-analysis" :value="project" @on-edit="$refs['seg-basic']?$refs['seg-basic'].edit():''" />
       </div>
        <div ref="progress" class="cm-box">
          <CmProjectProgress ref="seg-progress" :data="project"  @on-update="getData" />
        </div>
          <div ref="billplan" class="cm-box">
          <CmProjectBillPlan ref="seg-billplan" :data="project" @on-update="getData" />
        </div>
        <div ref="hr" class="cm-box">
          <CmProjectHr ref="seg-hr" :data="project"  @on-update="getData" />
        </div>
        <div ref="bp" class="cm-box">
          <CmProjectBp ref="seg-bp" :data="project"  @on-update="getData" />
        </div>
         <div ref="memo" class="cm-box">
          <CmProjectImportant ref="seg-memo" :data="project" />
        </div>
        
        <div ref="qa" class="cm-box">
          <CmProjectDebug ref="seg-qa" :data="project" />
        </div>
        <div class="flex-wrap flex-center" style="margin-top:40px;margin-bottom:10px">
          <a @click="handleOpen('basic')">返回顶部</a>
        </div>
        </div>
    </div>
    <div class="flex-wrap flex-center float-btn" style="right:20px;top:20px;" title="全部折叠" @click="foldAll">
      <BaseIcon icon="subtract" />
    </div>
    <div class="flex-wrap flex-center float-btn" style="right:20px;top:80px;" title="全部展开" @click="unfoldAll">
      <BaseIcon icon="add" />
    </div>
    <div class="flex-wrap flex-center float-btn" style="right:20px;top:140px;" title="全部展开" @click="showTasks=true">
      <BaseIcon icon="wenhao" size="20" color="var(--warning)" />
    </div>

    <Drawer v-model="showTasks" title="项目部操作说明" :width="500">
      <div class="flex-wrap flex-line align-start">
        <div class="flex-wrap flex-center" style="width:50px;height:50px;background:var(--bg2);border-radius:5px;flex-shrink:0">
        <BaseIcon icon="info" size="25" color="var(--primary)"></BaseIcon>
        </div>
        <div style="margin-left:20px">
          <div style="font-size:20px;">
            项目信息 <span style="font-size:12px;color:var(--primary)">首次/按需</span>
          </div>
          <div style="color:var(--subtext3)">
            包含合同相关信息、项目建筑类信息、项目定位、管理重要时间节点、项目权限等内容
          </div>
        </div>
        
      </div>
      <div class="flex-wrap flex-line  align-start" style="margin-top:15px">
        <div class="flex-wrap flex-center" style="width:50px;height:50px;background:var(--bg2);border-radius:5px;flex-shrink:0">
        <BaseIcon icon="info" size="25" color="var(--primary)"></BaseIcon>
        </div>
        <div style="margin-left:20px">
          <div style="font-size:20px;">
            总控计划 <span style="font-size:12px;color:var(--primary)">首次/按需</span>
          </div>
          <div style="color:var(--subtext3)">
            同事业部共同指定项目的首版总控计划，作为项目管理的重要指标
          </div>
        </div>
        
      </div>

       <div class="flex-wrap flex-line  align-start" style="margin-top:15px">
        <div class="flex-wrap flex-center" style="width:50px;height:50px;background:var(--bg2);border-radius:5px;flex-shrink:0">
        <BaseIcon icon="info" size="25" color="var(--primary)"></BaseIcon>
        </div>
        <div style="margin-left:20px">
          <div style="font-size:20px;">
            调整计划 <span style="font-size:12px;color:var(--primary)">按需</span>
          </div>
          <div style="color:var(--subtext3)">
            若出现工期、人员变更，请及时对总控计划进行调整，并提交新版的总控计划
          </div>
        </div>
        
      </div>
    </Drawer>
  </div>
</template>

<script>
  import CmProjectHeader from '@/components/cm/project/ProjectHeader'
  import CmProjectInfo from '@/components/cm/project/ProjectInfo'
  import CmProjectImage from '@/components/cm/project/ProjectImage'
  import CmProjectMap from '@/components/cm/project/ProjectMap'
  import CmProjectProgress from '@/components/cm/project/ProjectProgress'
  import CmProjectBillPlan from '@/components/cm/project/ProjectBillPlan'
  import CmProjectHr from '@/components/cm/project/ProjectHr'
  import CmProjectImportant from '@/components/cm/project/ProjectImportant'
  import CmProjectBp from '@/components/cm/project/ProjectBp'
  import CmProjectAnalysis from '@/components/cm/project/ProjectAnalysis'
  import CmProjectDebug from '@/components/cm/project/ProjectDebug'
  
import responsableMixin from '@/mixins/responsable'
  import moment from 'moment'
  import {cloneDeep} from 'lodash'
  import UTIL from '@/utils'
  moment.locale("zh-CN")
  export default {
    components:{CmProjectHeader,CmProjectInfo,CmProjectImage,CmProjectMap,CmProjectAnalysis,CmProjectProgress,CmProjectBillPlan,CmProjectHr,CmProjectImportant,CmProjectBp,CmProjectDebug},
    data(){
      return {
        actived:'basic',
        edit_memo:false,
        showTasks:false,
        project:{}
      }
    },
    
  mixins:[responsableMixin],
    watch:{
      id(){
        setTimeout(this.getData,500)
      }
    },
    computed:{
      sm(){
      return this.w < 1200
    },
      menus(){
        return [{
          name:"项目信息",
          key:"basic",
          icon:"iconset0118"
        },{
          name:"指标评价",
          key:"analysis",
          icon:"md-podium"
        },{
          name:"签约计划",
          key:"progress",
          icon:"date"
        },{
          name:"收费计划",
          key:"billplan",
          icon:"money"
        },{
          name:"人员投入",
          key:"hr",
          icon:"supervisor"
        },{
          name:"建安工程量统计",
          key:"bp",
          icon:"area-chart"
        },{
          name:"重要事项及风险提示",
          key:"memo",
          icon:"md-alert"
        },{
          name:"问题反馈",
          key:"qa",
          icon:"wenhao"
        }]
      },
      id(){
        return this.$route.params.id
      }
    }, 
    mounted(){
      this.getData()
    },
    methods:{
      foldAll(){
        this.menus.forEach(v=>{
          this.$refs["seg-"+v.key].$children[0].fold()
        })
      },
      unfoldAll(){
        this.menus.forEach(v=>{
           this.$refs["seg-"+v.key].$children[0].unfold()
        })
      },
      calcProduction(p){
        p.production_total = parseInt(p.amount /100)/100
  let production_current = 0
  let now = moment()


  // origin_progress_plan
  let nodes = cloneDeep(p.nodes.filter(v=>v.version=='origin'))
  if(nodes.length == 0){
    // no progress plan
  }else{
    p.origin_nodes = nodes
    p.current_nodes = cloneDeep(nodes)
    p.current_nodes.forEach(v=>{
      let versions = p.nodes.filter(n=>n.version_to == v.id)
      versions.sort((a,b)=>(moment(a.version).isAfter(moment(b.version)?1:-1)))
      versions.forEach(n=>{
        Object.assign(v,n)
      })
    })
  }

 


  if(Array.isArray(p.nodes)){
    for(let i=0;i<p.nodes.length;i++){
      let node = p.nodes[i]
      node.duration = node.duration || node.plan_duration
      node.plan_finished_at = moment(node.plan_started_at).add(node.duration,"days")
      node.started_at = node.started_at || node.plan_started_at
      node.duration = node.finished_at ? moment.duration(moment(node.finished_at) - moment(node.started_at)).as('days'):node.plan_duration

      if(moment(node.started_at).isAfter(moment())){
        let current_duration = moment.duration(now - node.started_at).as("days")
        let percent = current_duration < node.duration ? current_duration / node.duration : node.duration
        let num = percent * node.percent * p.amount / node.duration
        if(!isNaN(num))
          production_current += Math.floor(num) / 100
        break
      }else{
         let num = node.percent * p.amount
         if (!isNaN(num))
           production_current += Math.floor(num) / 100
      }
    }
  }else{
    if(p.started_at && p.completed_at){
      p.production_current = moment.duration(now - moment(p.started_at)).as('days') * p.amount / moment.duration(moment(p.completed_at) - moment(p.started_at)).as('days') 
    }
  }

  p.production_current = parseInt(production_current / 100) / 100
  p.production_plan = parseInt(p.production_total / 100) / 100

      },
      getData(){
        this.$api.get(`projects/${this.id}?q=cm`).then(res=>{
          let project = res.data.data
          project.condition_groups.forEach(g=>{
            g.conditions = project.conditions.filter(c=>c.business_type == g.business_type)
          })
          document.title = project.name
          this.project = project
        })
      },
      handleOpen(e){
        this.actived = e
        this.$refs.container.scrollTop = this.$refs[e].offsetTop
      },
      handleSaveProjectInfo(data){

      }
    }
  }
</script>

<style lang="less" scoped>
.cm-page{
  height:100%;background:var(--bg2)
}
.cm-page-left{
  width:200px;height:100%;border-right:1px solid var(--border);flex-shrink:0;flex-grow:0
}
.cm-page-right{
  height:100%;width:100%;overflow-y:auto;position:relative;scroll-behavior:smooth
}

.cm-page-inner{
  width:90%;min-width:1000px;max-width:1200px;margin:20px auto;overflow:hidden;
}

.cm-box{
  margin-bottom:10px;position:relative;border:1px solid var(--border);overflow:hidden;
}

.float-btn{
  width:50px;height:50px;
  background:var(--border);position:absolute;cursor:pointer;
}
</style>
