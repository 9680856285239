<template>
  <div class="db-monitor">
    <div class="monitor-header">
      <h2>数据库监控仪表盘</h2>
      <div class="header-controls">
        <Button type="primary" @click="refreshData">
          刷新数据
        </Button>
        <Button type="primary" @click="showThresholdModal">
          预警阈值设置
        </Button>
      </div>
    </div>

    <!-- 数据库表格视图 -->
    <Card class="data-table-card">
      <div class="table-header">
        <h3>数据量监控</h3>
      </div>
      
      <div class="table-container">
        <Table 
          :columns="columns" 
          :data="tableRows" 
          :loading="loading" 
          border 
          :height="tableHeight"
        >
          <template slot-scope="{ row }" slot="name">
            <a href="javascript:void(0)" @click="showTableHistory(row)">{{ row.name }}</a>
          </template>
          <template slot-scope="{ row, column }" slot="alert">
            <div v-if="column.key === 'alert'">
              <Tag :color="row.alert.color" @click.native="showAlertDetail(row)" style="cursor: pointer;">
                {{ row.alert.text }}
              </Tag>
            </div>
          </template>
          <template slot-scope="{ row, column }" slot="count">
            <div v-if="column.key === 'count'">
              <span>{{ formatNumber(row.count) }}</span>
            </div>
          </template>
          <template slot-scope="{ row, column }" slot="yesterdayCount">
            <div v-if="column.key === 'yesterdayCount'">
              <span>{{ formatNumber(row.yesterdayCount) }}</span>
            </div>
          </template>
          <template slot-scope="{ row, column }" slot="trend">
            <div v-if="column.key === 'trend'">
              <span :class="['trend', row.trendClass]">{{ row.trend }}</span>
            </div>
          </template>
          <template slot-scope="{ row }" slot="remark">
            <div v-if="row.alert.color !== 'success'">
              {{ row.remarkText }}
            </div>
            <div v-else>-</div>
          </template>
        </Table>
      </div>
    </Card>

    <!-- 预警阈值设置弹窗 -->
    <Modal v-model="thresholdModalVisible" title="预警阈值设置">
      <Form :model="thresholdSettings" label-position="top">
        <FormItem label="增长预警阈值 (%)">
          <InputNumber 
            v-model="thresholdSettings.increaseThreshold" 
            :min="0"
            :max="100"
            :step="1"
            placeholder="请输入增长预警阈值百分比"
          />
          <span style="margin-left: 8px;">触发橙色预警</span>
        </FormItem>
        <FormItem label="减少预警阈值 (%)">
          <InputNumber 
            v-model="thresholdSettings.decreaseThreshold" 
            :min="0"
            :max="100"
            :step="1"
            placeholder="请输入减少预警阈值百分比"
          />
          <span style="margin-left: 8px;">触发红色预警</span>
        </FormItem>
      </Form>
      <template #footer>
        <Button type="primary" @click="saveThresholdSettings">保存</Button>
        <Button @click="thresholdModalVisible = false">取消</Button>
      </template>
    </Modal>

    <!-- 预警详情弹窗 -->
    <Modal v-model="alertDetailVisible" :title="alertDetailTitle" width="1000">
      <div class="alert-detail">
        <div class="alert-info">
          <p><strong>表名：</strong>{{ alertDetail.name }}</p>
          <p><strong>当前数据量：</strong>{{ formatNumber(alertDetail.count) }} 条</p>
          <p>
            <strong>最近一天变化：</strong>
            <span :class="alertDetail.trendClass">{{ alertDetail.change }}</span>
          </p>
          <p><strong>预警统计：</strong>{{ alertDetail.reason }}</p>
        </div>
        
        <Divider orientation="left">异常记录列表</Divider>
        
        <Table :columns="anomalyColumns" :data="alertDetail.anomalies || []" size="small" border>
          <template slot-scope="{ row }" slot="change">
            <span :class="row.change > 0 ? 'trend-up' : 'trend-down'">
              {{ row.change > 0 ? '+' : '' }}{{ formatNumber(row.change) }} ({{ row.changeRate }})
            </span>
          </template>
          <template slot-scope="{ row }" slot="status">
            <Tag :color="row.status === 'red' ? 'error' : 'warning'">
              {{ row.status === 'red' ? '异常减少' : '异常增长' }}
            </Tag>
          </template>
        </Table>
        
        <Divider orientation="left">趋势图</Divider>
        <div class="history-data">
          <div class="mini-chart" ref="miniChartContainer"></div>
        </div>
      </div>
    </Modal>
    
    <!-- 表格历史数据弹窗 -->
    <Modal v-model="tableHistoryVisible" :title="tableHistoryTitle" width="1000">
      <Table :columns="historyColumns" :height="400" :data="historyData" border>
        <template slot-scope="{ row }" slot="trend">
          <span :class="['trend', row.trendClass]">{{ row.trendText }}</span>
        </template>
        <template slot-scope="{ row }" slot="status">
          <Tag :color="row.statusColor">{{ row.statusText }}</Tag>
        </template>
      </Table>
    </Modal>
  </div>
</template>

<script>
import { Button, Card, Tag, Modal, Form, FormItem, InputNumber, Notice, Message, Table, Divider } from 'view-design'
import * as echarts from 'echarts'

export default {
  name: 'DatabaseMonitor',
  components: {
    Button,
    Card,
    Tag,
    Modal,
    Form,
    FormItem,
    InputNumber,
    Table,
    Divider
  },
  data() {
    return {
      loading: false,
      tableData: [],
      historicalData: [],
      miniChart: null,
      tableHeight: 500, // 默认表格高度
      thresholdModalVisible: false,
      alertDetailVisible: false,
      alertDetailTitle: '',
      alertDetail: {},
      tableHistoryVisible: false,
      tableHistoryTitle: '',
      historyData: [],
      thresholdSettings: {
        increaseThreshold: 20, // 默认增长阈值设置为20%
        decreaseThreshold: 5, // 默认减少阈值设置为5%
      },
      columns: [
        {
          title: '数据表名称',
          key: 'name',
          slot: 'name',
          fixed: 'left',
          width: 200
        },
        {
          title: '当前数据量',
          key: 'count',
          width: 150,
          slot: 'count'
        },
        {
          title: '昨日数据量',
          key: 'yesterdayCount',
          width: 150,
          slot: 'yesterdayCount'
        },
        {
          title: '预警状态',
          slot: 'alert',
          key: 'alert',
          width: 120
        },
        {
          title: '变化趋势',
          slot: 'trend',
          key: 'trend',
          width: 180
        },
        {
          title: '备注',
          slot: 'remark',
          key: 'remark',
          width: 250
        },
        {
          title: '最后更新时间',
          key: 'updated_at',
          width: 180
        }
      ],
      historyColumns: [
        {
          title: '序号',
          type: 'index',
          width: 70
        },
        {
          title: '日期',
          key: 'date',
          width: 120
        },
        {
          title: '数据量',
          key: 'count',
          width: 150
        },
        {
          title: '参照日期',
          key: 'comparedTo',
          width: 120
        },
        {
          title: '增长量',
          key: 'change',
          width: 150
        },
        {
          title: '增长率',
          slot: 'trend',
          width: 150
        },
        {
          title: '状态',
          slot: 'status',
          width: 100
        }
      ],
      anomalyColumns: [
        {
          title: '日期',
          key: 'date',
          width: 120
        },
        {
          title: '数据量',
          key: 'count',
          width: 120,
          render: (h, params) => {
            return h('span', this.formatNumber(params.row.count));
          }
        },
        {
          title: '参照日期',
          key: 'previousDate',
          width: 120
        },
        {
          title: '前一日数据量',
          key: 'previousCount',
          width: 120,
          render: (h, params) => {
            return h('span', this.formatNumber(params.row.previousCount));
          }
        },
        {
          title: '变化趋势',
          slot: 'change',
          width: 180
        },
        {
          title: '状态',
          slot: 'status',
          width: 100
        }
      ],
      tableRows: []
    }
  },
  mounted() {
    this.calculateTableHeight();
    this.fetchData();
    
    // 监听窗口大小变化
    window.addEventListener('resize', this.calculateTableHeight);
  },
  beforeDestroy() {
    if (this.miniChart) {
      this.miniChart.dispose();
    }
    
    // 清除窗口事件监听
    window.removeEventListener('resize', this.calculateTableHeight);
  },
  methods: {
    // 计算表格高度
    calculateTableHeight() {
      const headerHeight = 80; // 标题高度
      const tableHeaderHeight = 60; // 表格标题高度
      const padding = 40; // 页面内边距
      const otherSpace = 10; // 其他元素占用的空间

      // 计算可用高度
      this.tableHeight = window.innerHeight - headerHeight - tableHeaderHeight - padding - otherSpace;
    },

    formatNumber(value) {
      if (value === null || value === undefined) {
        return '0';
      }
      
      // 处理大于1000的数字
      if (value >= 1000) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      
      return value.toString();
    },
    
    formatTime(time) {
      if (!time) {
        return '未知时间';
      }
      
      const date = typeof time === 'object' ? time : new Date(time);
      
      if (isNaN(date.getTime())) {
        return '无效时间';
      }
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    
    formatDate(time) {
      if (!time) {
        return '未知日期';
      }
      
      const date = typeof time === 'object' ? time : new Date(time);
      
      if (isNaN(date.getTime())) {
        return '无效日期';
      }
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return year + '-' + month + '-' + day;
    },
    
    async fetchData() {
      try {
        this.loading = true;
        const response = await this.$api.get("/admin/zzl_db_counts");
        
        // 处理API返回的数据
        if (!response || !response.data) {
          console.warn('API未返回有效数据');
          this.loading = false;
          return;
        }
        
        console.log('API原始返回数据:', response.data);
        
        // 获取API数据数组
        let apiData = [];
        if (response.data.data && Array.isArray(response.data.data)) {
          apiData = response.data.data;
        } else if (Array.isArray(response.data)) {
          apiData = response.data;
        }
        
        if (apiData.length === 0) {
          console.warn('API未返回有效数据数组');
          this.loading = false;
          return;
        }
        
        console.log('API数据数组:', apiData);
        
        // 按照日期分组所有数据
        const groupedByDate = {};
        
        // 遍历每条记录
        apiData.forEach((record, index) => {
          // 检查记录格式
          console.log(`处理第${index+1}条记录:`, record);
          
          // 获取日期
          let createdDate = null;
          if (record.created_by) {
            createdDate = record.created_by;
          } else if (record.created_at) {
            createdDate = record.created_at;
          } else {
            console.warn(`第${index+1}条记录缺少日期字段`);
            return;
          }
          
          // 解析日期
          const dateObj = new Date(createdDate);
          if (isNaN(dateObj.getTime())) {
            console.warn(`日期解析错误: ${createdDate}`);
            return;
          }
          
          const dateStr = this.formatDate(dateObj);
          console.log(`日期: ${dateStr}, 原始日期: ${createdDate}`);
          
          // 获取数据表数组
          let tableArray = [];
          if (record.data && Array.isArray(record.data)) {
            tableArray = record.data;
          } else {
            console.warn(`第${index+1}条记录缺少数据表数组`);
            return;
          }
          
          console.log(`表数据数量: ${tableArray.length}`);
          
          // 初始化日期分组
          if (!groupedByDate[dateStr]) {
            groupedByDate[dateStr] = {
              date: dateStr,
              timestamp: dateObj,
              tables: {}
            };
          }
          
          // 处理每个表的数据
          tableArray.forEach(tableItem => {
            if (!tableItem) return;
            
            // 检查表数据格式
            console.log('处理表数据:', tableItem);
            
            // 获取表名和计数
            const tableName = tableItem.name;
            let count = 0;
            
            if (tableItem.count !== undefined) {
              count = parseInt(tableItem.count) || 0;
            }
            
            if (!tableName) {
              console.warn('表缺少名称字段');
              return;
            }
            
            // 存储表数据
            groupedByDate[dateStr].tables[tableName] = {
              name: tableName,
              count: count
            };
          });
        });
        
        console.log('按日期分组结果:', groupedByDate);
        
        // 检查分组结果
        const dateCount = Object.keys(groupedByDate).length;
        if (dateCount === 0) {
          console.warn('按日期分组后没有数据');
          this.loading = false;
          return;
        }
        
        console.log(`共有${dateCount}个日期的数据`);
        
        // 将分组数据转换为历史数据数组
        const newHistoricalData = Object.values(groupedByDate).map(dateGroup => {
          const tableData = Object.values(dateGroup.tables);
          console.log(`日期 ${dateGroup.date} 有 ${tableData.length} 张表的数据`);
          
          return {
            timestamp: dateGroup.timestamp,
            data: tableData
          };
        });
        
        // 排序历史数据（按日期升序）
        newHistoricalData.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
        
        console.log('转换后的历史数据:', newHistoricalData.map(d => ({
          date: this.formatDate(d.timestamp),
          tables: d.data.length,
          firstTable: d.data.length > 0 ? d.data[0].name : 'none'
        })));
        
        // 更新历史数据
        this.historicalData = newHistoricalData;
        
        // 不需要生成测试
        if (this.historicalData.length < 2 && this.historicalData.length > 0) {
         
        }
        
        // 限制历史数据长度，保留最近30天的数据
        const maxDays = 30;
        if (this.historicalData.length > maxDays) {
          this.historicalData = this.historicalData.slice(-maxDays);
        }
        
        // 更新当前数据 - 使用最新的一天数据
        if (this.historicalData.length > 0) {
          const latestData = this.historicalData[this.historicalData.length - 1];
          console.log('使用最新数据:', this.formatDate(latestData.timestamp), 
                    '表数量:', latestData.data.length,
                    '第一张表:', latestData.data.length > 0 ? latestData.data[0].name : 'none');
          
          this.tableData = latestData.data;
          
          // 更新表格数据
          this.updateTableRows();
          
          // 检查预警 - 只对有至少两天数据的表进行预警
          if (this.historicalData.length >= 2) {
            this.checkAlerts();
          }
        } else {
          console.warn('没有可用的历史数据');
        }
        
        this.loading = false;
      } catch (error) {
        console.error('获取数据失败:', error);
        this.$Message.error('获取数据失败');
        this.loading = false;
      }
    },
    
    updateTableRows() {
      // 确保使用最新的数据
      const currentData = this.tableData;
      
      this.tableRows = currentData.map(table => {
        const status = this.getTableStatus(table);
        const trendInfo = this.getTrendInfo(table);
        const referenceData = this.getPreviousDayData(table.name);
        const referenceTimestamp = this.getPreviousDayTimestamp(table.name);
        const referenceDate = this.formatDate(referenceTimestamp);
        
        // 从最新数据获取时间戳
        const timestamp = this.historicalData.length > 0 
          ? this.historicalData[this.historicalData.length - 1].timestamp 
          : new Date();
        
        // 生成备注信息 - 显示红色和橙色预警的次数
        let remarkText = '';
        if (status.color !== 'success') {
          let alertItems = [];
          
          if (status.redCount > 0) {
            alertItems.push(`${status.redCount}次红色预警`);
          }
          
          if (status.orangeCount > 0) {
            alertItems.push(`${status.orangeCount}次橙色预警`);
          }
          
          remarkText = `过去30天内: ${alertItems.join('，')}`;
        }
        
        return {
          name: table.name,
          count: table.count,
          yesterdayCount: referenceData ? referenceData.count : 0,
          alert: {
            color: status.color,
            text: status.text
          },
          trend: trendInfo.text,
          trendClass: trendInfo.class,
          referenceData: referenceData,
          referenceDate: referenceDate,
          remarkText: remarkText,
          updated_at: this.formatTime(timestamp)
        };
      }).sort((a, b) => {
        // 超出阈值的数据排在前面
        if (a.alert.color === 'error' && b.alert.color !== 'error') {
          return -1;
        }
        if (a.alert.color !== 'error' && b.alert.color === 'error') {
          return 1;
        }
        // 然后是异常变化的数据
        if (a.alert.color === 'warning' && b.alert.color !== 'warning') {
          return -1;
        }
        if (a.alert.color !== 'warning' && b.alert.color === 'warning') {
          return 1;
        }
        // 最后按表名排序
        return a.name.localeCompare(b.name);
      });
    },
    
    getTrendInfo(table) {
      // 使用前一天的数据作为参照
      const previousData = this.getPreviousDayData(table.name);
      if (!previousData) {
        return { text: '无变化', class: '', change: 0, changeRate: 0 };
      }
      
      const change = table.count - previousData.count;
      const changeRate = ((change / previousData.count) * 100).toFixed(2);
      
      if (change > 0) {
        return { 
          text: '↑ ' + this.formatNumber(change) + ' (' + changeRate + '%)', 
          class: 'trend-up',
          change: change,
          changeRate: changeRate
        };
      }
      if (change < 0) {
        return { 
          text: '↓ ' + this.formatNumber(Math.abs(change)) + ' (' + Math.abs(changeRate) + '%)', 
          class: 'trend-down',
          change: change,
          changeRate: changeRate
        };
      }
      return { text: '无变化', class: '', change: 0, changeRate: 0 };
    },
    
    getTableStatus(table) {
      // 如果没有任何历史数据，返回正常状态
      if (this.historicalData.length < 2) {
        return { color: 'success', text: '正常' };
      }
      
      const tableName = table.name;
      
      // 分析过去30天的数据，统计红色和橙色预警次数
      let redAlertCount = 0;
      let orangeAlertCount = 0;
      
      // 按日期倒序排列，以便统计最近30天的数据
      const sortedHistory = [...this.historicalData].sort((a, b) => 
        b.timestamp.getTime() - a.timestamp.getTime()
      );
      
      // 限制只分析最近30天的数据
      const recentHistory = sortedHistory.slice(0, 30);
      
      // 逐日比较，检查每天相对前一天的变化
      for (let i = 0; i < recentHistory.length - 1; i++) {
        const currentDay = recentHistory[i];
        const previousDay = recentHistory[i + 1];
        
        // 找到当前表数据
        const currentTableData = currentDay.data.find(item => item && item.name === tableName);
        const prevTableData = previousDay.data.find(item => item && item.name === tableName);
        
        if (!currentTableData || !prevTableData || prevTableData.count <= 0) continue;
        
        // 计算变化率 - 由于是倒序排列，计算公式是：当前 - 前一天
        const change = currentTableData.count - prevTableData.count;
        const changeRate = Math.abs((change / prevTableData.count) * 100);
        
        // 统计预警情况
        if (change > 5 && changeRate > this.thresholdSettings.increaseThreshold) {
          orangeAlertCount++; // 橙色预警
        } else if (change < -5 && changeRate > this.thresholdSettings.decreaseThreshold) {
          redAlertCount++; // 红色预警
        }
      }
      
      // 根据预警次数确定状态
      if (redAlertCount > 0) {
        return { 
          color: 'error', 
          text: '异常减少', 
          redCount: redAlertCount, 
          orangeCount: orangeAlertCount 
        };
      } else if (orangeAlertCount > 0) {
        return { 
          color: 'warning', 
          text: '异常增长', 
          redCount: redAlertCount, 
          orangeCount: orangeAlertCount 
        };
      }
      
      return { 
        color: 'success', 
        text: '正常',
        redCount: 0,
        orangeCount: 0
      };
    },
    
    // 获取前一天的数据
    getPreviousDayData(tableName) {
      // 确保有至少两天的数据
      if (this.historicalData.length < 2) {
        return null;
      }
      
      // 获取最新的数据记录和次新的数据记录
      const lastDataPoint = this.historicalData[this.historicalData.length - 1];
      const prevDataPoint = this.historicalData[this.historicalData.length - 2];
      
      if (!prevDataPoint || !Array.isArray(prevDataPoint.data)) {
        return null;
      }
      
      // 从次新的数据记录中寻找对应表名的数据
      const tableData = prevDataPoint.data.find(item => 
        item && typeof item === 'object' && item.name === tableName
      );
      
      if (tableData) {
        return {
          ...tableData,
          _date: this.formatDate(prevDataPoint.timestamp),
          _simulated: prevDataPoint._simulated || false
        };
      }
      
      return null;
    },
    
    // 获取前一天数据的时间戳
    getPreviousDayTimestamp(tableName) {
      // 确保有至少两天的数据
      if (this.historicalData.length < 2) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
      }
      
      // 获取次新的数据记录的时间戳
      return this.historicalData[this.historicalData.length - 2].timestamp;
    },
    
    // 获取当前数据
    getCurrentData(tableName) {
      // 确保使用最新的一天数据
      if (this.historicalData.length > 0) {
        const latestData = this.historicalData[this.historicalData.length - 1].data;
        if (Array.isArray(latestData)) {
          return latestData.find(item => item && typeof item === 'object' && item.name === tableName);
        }
      }
      
      return this.tableData.find(item => item && typeof item === 'object' && item.name === tableName);
    },
    
    checkAlerts() {
      // 统计预警数量
      const errorItems = this.tableRows.filter(row => row.alert.color === 'error');
      const warningItems = this.tableRows.filter(row => row.alert.color === 'warning');
      
      // 只在有预警时显示汇总通知
      if (errorItems.length > 0 || warningItems.length > 0) {
        let alertMessage = '';
        
        if (errorItems.length > 0) {
          alertMessage += `${errorItems.length}个数据表异常增长`;
        }
        
        if (warningItems.length > 0) {
          if (alertMessage) alertMessage += '，';
          alertMessage += `${warningItems.length}个数据表异常减少`;
        }
        
      }
    },
    
    showThresholdModal() {
      this.thresholdModalVisible = true;
    },
    
    saveThresholdSettings() {
      this.thresholdModalVisible = false;
      // 更新表格数据以反映新的阈值设置
      this.updateTableRows();
      this.$Message.success('阈值设置已保存');
    },
    
    refreshData() {
      this.fetchData();
    },
    
    // 显示预警详情
    showAlertDetail(row) {
      if (row.alert.color === 'success') return; // 正常状态不显示详情
      
      // 收集该表在所有历史记录中的近期异常数据
      const anomalies = [];
      const tableName = row.name;
      
      // 按日期倒序排列，最新的在前面
      const sortedHistory = [...this.historicalData].sort((a, b) => 
        b.timestamp.getTime() - a.timestamp.getTime()
      );
      
      // 限制只分析最近30天的数据
      const recentHistory = sortedHistory.slice(0, 30);
      
      // 逐日比较，检查每天相对前一天的变化
      for (let i = 0; i < recentHistory.length - 1; i++) {
        const currentDay = recentHistory[i];
        const previousDay = recentHistory[i + 1];
        
        // 找到当前表数据
        const currentTableData = currentDay.data.find(item => item && item.name === tableName);
        const prevTableData = previousDay.data.find(item => item && item.name === tableName);
        
        if (!currentTableData || !prevTableData || prevTableData.count <= 0) continue;
        
        // 计算变化率 - 由于是倒序排列，计算公式是：当前 - 前一天
        const change = currentTableData.count - prevTableData.count;
        const changeRate = Math.abs((change / prevTableData.count) * 100);
        const changeRateFormatted = ((change / prevTableData.count) * 100).toFixed(2) + '%';
        
        let status = 'normal';
        
        // 判断预警情况
        if (change > 5 && changeRate > this.thresholdSettings.increaseThreshold) {
          status = 'orange'; // 橙色预警
        } else if (change < -5 && changeRate > this.thresholdSettings.decreaseThreshold) {
          status = 'red'; // 红色预警
        }
        
        // 只收集异常数据
        if (status !== 'normal') {
          anomalies.push({
            date: this.formatDate(currentDay.timestamp),
            count: currentTableData.count,
            previousCount: prevTableData.count,
            previousDate: this.formatDate(previousDay.timestamp),
            change: change,
            changeRate: changeRateFormatted,
            status: status
          });
        }
      }
      
      // 获取最新一天的数据作为当前显示
      const currentData = row;
      const referenceData = row.referenceData || {};
      
      this.alertDetail = {
        name: row.name,
        count: row.count,
        referenceCount: referenceData.count || 0,
        change: currentData.trend,
        changeRate: currentData.trend,
        trendClass: currentData.trendClass,
        reason: `过去30天内出现了 ${anomalies.filter(a => a.status === 'red').length} 次红色预警和 ${anomalies.filter(a => a.status === 'orange').length} 次橙色预警`,
        time: row.updated_at,
        referenceDate: row.referenceDate || '无参照日期',
        isSimulated: referenceData._simulated,
        anomalies: anomalies
      };
      
      this.alertDetailTitle = row.name + ' 数据异常详情';
      this.alertDetailVisible = true;
      
      // 延迟初始化小图表，确保DOM已经渲染
      this.$nextTick(() => {
        this.initMiniChart(row.name);
      });
    },
    
    // 初始化预警详情的小图表
    initMiniChart(tableName) {
      if (this.miniChart) {
        this.miniChart.dispose();
      }
      
      const container = this.$refs.miniChartContainer;
      if (!container) return;
      
      this.miniChart = echarts.init(container);
      
      // 获取该表的历史数据
      const data = this.historicalData.map(point => {
        const tableData = Array.isArray(point.data) 
          ? point.data.find(item => item && item.name === tableName)
          : null;
        return [
          point.timestamp, 
          tableData ? tableData.count : 0
        ];
      });
      
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const time = this.formatTime(params[0].axisValue);
            return time + '<br/>' + this.formatNumber(params[0].value[1]) + ' 条';
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => this.formatNumber(value)
          },
          scale: true
        },
        series: [{
          name: tableName,
          type: 'line',
          data: data,
          markPoint: {
            data: [
              { type: 'max', name: '最大值' },
              { type: 'min', name: '最小值' }
            ]
          }
        }]
      };
      
      this.miniChart.setOption(option);
    },
    
    // 显示表格历史数据
    showTableHistory(row) {
      this.tableHistoryTitle = `${row.name} 历史数据详情`;
      
      // 收集该表在所有历史记录中的数据
      const history = [];
      const tableName = row.name;
      
      // 按日期倒序排列，最新的在前面
      const sortedHistory = [...this.historicalData].sort((a, b) => 
        b.timestamp.getTime() - a.timestamp.getTime()
      );
      
      // 对于每一天，找到下一天（历史上的前一天）进行比较
      for (let i = 0; i < sortedHistory.length; i++) {
        const currentPoint = sortedHistory[i];
        const tableData = currentPoint.data.find(item => item && item.name === tableName);
        if (!tableData) continue;
        
        const count = tableData.count;
        const date = this.formatDate(currentPoint.timestamp);
        
        // 查找下一天（历史上的前一天）的数据
        let nextPointData = null;
        if (i < sortedHistory.length - 1) {
          const nextPoint = sortedHistory[i + 1];
          const nextTableData = nextPoint.data.find(item => item && item.name === tableName);
          if (nextTableData) {
            nextPointData = {
              count: nextTableData.count,
              date: this.formatDate(nextPoint.timestamp)
            };
          }
        }
        
        // 计算变化量和变化率
        let change = 0;
        let changeRate = 0;
        let trendClass = '';
        let trendText = '无变化';
        let statusColor = 'success';
        let statusText = '正常';
        
        if (nextPointData && nextPointData.count > 0) {
          // 由于是倒序排列，计算公式是：当前 - 下一项（历史上的前一天）
          change = count - nextPointData.count;
          changeRate = ((change / nextPointData.count) * 100).toFixed(2);
          
          if (change > 0) {
            trendText = `+${this.formatNumber(change)} (${changeRate}%)`;
            trendClass = 'trend-up';
          } else if (change < 0) {
            trendText = `${this.formatNumber(change)} (${changeRate}%)`;
            trendClass = 'trend-down';
          }
          
          // 判断是否超过阈值
          if (change > 5 && Math.abs(changeRate) > this.thresholdSettings.increaseThreshold) {
            statusColor = 'warning';
            statusText = '异常增长';
          } else if (change < -5 && Math.abs(changeRate) > this.thresholdSettings.decreaseThreshold) {
            statusColor = 'error';
            statusText = '异常减少';
          }
        }
        
        history.push({
          date,
          count: this.formatNumber(count),
          change: change !== 0 ? (change > 0 ? `+${this.formatNumber(change)}` : this.formatNumber(change)) : '-',
          changeRate,
          trendText,
          trendClass,
          statusColor,
          statusText,
          isSimulated: currentPoint._simulated || false,
          comparedTo: nextPointData ? nextPointData.date : '无参照日期'
        });
      }
      
      this.historyData = history;
      this.tableHistoryVisible = true;
    }
  }
}
</script>

<style lang="less" scoped>
.db-monitor {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px); // 减少顶部空间，使表格能占据更多空间
  
  .monitor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    h2 {
      margin: 0;
      font-size: 24px;
      color: #17233d;
    }
    
    .header-controls {
      display: flex;
      gap: 10px;
    }
  }
  
  .data-table-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      
      h3 {
        margin: 0;
        font-size: 16px;
        color: #17233d;
      }
    }
    
    .table-container {
      flex: 1;
      position: relative;
      overflow: hidden;
      
      /deep/ .ivu-table-wrapper {
        // 不设置固定高度，让Table的height属性控制
      }
      
      /deep/ .ivu-table {
        // Table已有高度属性
      }
      
      /deep/ .ivu-table-header-wrapper {
        overflow: hidden !important;
      }
      
      /deep/ .ivu-table-body {
        // 表格内容可以滚动
        overflow-y: auto !important; 
        overflow-x: auto !important;
      }
      
      /deep/ .ivu-table-fixed-header th {
        background: #f8f8f9;
      }
    }
    
    /deep/ a {
      color: #2d8cf0;
      text-decoration: none;
      
      &:hover {
        color: #5cadff;
        text-decoration: underline;
      }
    }
  }
  
  .trend {
    &.trend-up {
      color: #19be6b;
    }
    
    &.trend-down {
      color: #ed4014;
    }
  }
  
  // 预警详情样式
  .alert-detail {
    padding: 10px;
    
    .alert-info {
      margin-bottom: 20px;
      
      p {
        margin: 8px 0;
        line-height: 1.5;
      }
      
      .simulated-data-tag {
        display: inline-block;
        margin-left: 8px;
        padding: 2px 6px;
        font-size: 12px;
        color: #c5c8ce;
        background: #f8f8f9;
        border-radius: 3px;
      }
    }
    
    .history-data {
      h3 {
        margin: 0 0 15px 0;
        font-size: 16px;
        color: #17233d;
      }
      
      .mini-chart {
        height: 200px;
        width: 100%;
      }
    }
  }
}
</style>

