<template>
  <div class="container fixed-assets-page" style="position:relative;">
    <div class="page-header">
      <h2>固定资产管理</h2>
      <div class="header-actions">
        <Button type="primary" icon="md-add" @click="handleAdd">新增资产</Button>
      </div>
    </div>

      <div class="table-toolbar">
        <div class="left">
          <Input 
            search 
            style="width: 220px" 
            v-model="searchText" 
            placeholder="搜索资产名称/编号/备注" 
            clearable 
            @on-enter="handleSearch"
            @on-clear="handleSearch"
          />
          <Select v-model="filters.status" placeholder="资产状态" style="width: 120px" clearable @on-change="handleSearch">
            <Option value="使用中">使用中</Option>
            <Option value="闲置中">闲置中</Option>
            <Option value="已报废">已报废</Option>
          </Select>
          <Select v-model="filters.dep_id" placeholder="使用部门" style="width: 160px" clearable @on-change="handleSearch">
            <Option v-for="dep in deps" :key="dep.id" :value="dep.id">{{ dep.name }}</Option>
          </Select>
          <Button icon="md-search" type="primary" @click="handleSearch">搜索</Button>
          <Button icon="md-refresh" @click="getData(true)">刷新</Button>
        </div>
        <div class="right">
          <Button @click="exportExcel">导出 Excel</Button>
        </div>
      </div>

<div style="height:calc(100% - 100px)">
  
      <BaseTable
        ref="table"
        :columns="columns"
        :data="assets"
        :count="total"
        :loading="loading"
        border
        paged
        :sum="sum"
        :page-size="pagesize"
        :page="page"
        @event="handleTableEvent"
      >
        
      </BaseTable>
      
</div>

    <!-- 新增/编辑弹窗 -->
    <Modal v-model="showModal" :title="modalTitle" width="600" footer-hide>
      <Form ref="form" :model="formData" :rules="rules" label-position="right" :label-width="100">
        <FormItem label="资产编号" prop="asset_id">
          <Input v-model="formData.asset_id" placeholder="请输入资产编号" />
        </FormItem>
        <FormItem label="资产名称" prop="name">
          <Input v-model="formData.name" placeholder="请输入资产名称" />
        </FormItem>
        <FormItem label="资产类型" prop="type">
          <Input v-model="formData.type" placeholder="请输入资产类型" />
        </FormItem>
        <FormItem label="资产描述" prop="description">
          <Input type="textarea" :rows="6" v-model="formData.description" placeholder="请输入资产描述" />
        </FormItem>
        <FormItem label="购买时间" prop="purchase_time">
          <DatePicker 
            v-model="formData.purchase_time" 
            type="datetime" 
            placeholder="请选择购买时间"
            style="width: 100%"
          />
        </FormItem>
        <FormItem label="购买金额" prop="purchase_amount">
          <BaseAmountInput 
            :editable="true"
            v-model="formData.purchase_amount" 
            :precision="2"
            :step="0.01"
            style="width: 100%"
            placeholder="请输入购买金额"
          />
        </FormItem>
        <FormItem label="使用部门" prop="dep_id">
          <Select
            v-model="formData.dep_id"
            placeholder="请选择使用部门"
            style="width: 100%"
          >
            <Option v-for="dep in deps" :key="dep.id" :value="dep.id">{{ dep.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="使用人" prop="user_id">
          <BaseUserSelectGrouped v-model="formData.user_id" :option="{ users }" />
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
        </FormItem>
      </Form>
      <div class="modal-footer flex-center split5">
        
        <Button type="primary" @click="handleSubmit" :loading="submitting">确定</Button>
        <Button @click="showModal = false">取消</Button>
      </div>
    </Modal>

    <!-- 资产详情抽屉 -->
    <Drawer
      v-model="showDetailDrawer"
      :title="detailData.name"
      width="650"
      :closable="true"
      class="asset-detail-drawer"
    >
      <Spin v-if="detailLoading" size="large" fix></Spin>
      <div class="detail-content" v-else>
        <div class="detail-header flex-wrap flex-between" style="margin-bottom:10px;">
          <div class="asset-id-box">
            <Icon type="md-barcode" size="18" />
            <span class="asset-id" style="margin-left:10px;">{{ detailData.asset_id }}</span>
          </div>
          <Tag :color="getStatusColor(detailData.status)" class="status-tag">{{ detailData.status || '使用中' }}</Tag>
        </div>
        
        <Card class="detail-card">
          <p slot="title"><Icon type="md-information-circle" /> 基本信息</p>
          <div class="detail-grid">
            <div class="detail-item">
              <strong><Icon type="md-pricetag" /> 资产类型</strong>
              <span>{{ detailData.type }}</span>
            </div>
            <div class="detail-item">
              <strong><Icon type="logo-yen" /> 购买金额</strong>
              <span>{{ formatMoney(detailData.purchase_amount) }}</span>
            </div>
            <div class="detail-item">
              <strong><Icon type="md-calendar" /> 购买时间</strong>
              <span>{{ moment(detailData.purchase_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <div class="detail-item">
              <strong><BaseIcon icon="organization" /> 使用部门</strong>
              <span>{{ getDepNameById(detailData.dep_id) || '-' }}</span>
            </div>
            <div class="detail-item">
              <strong><Icon type="md-person" /> 使用人</strong>
              <span>{{ users.find(u => u.id === detailData.user_id) ? users.find(u => u.id === detailData.user_id).name : '-' }}</span>
            </div>
          </div>
        </Card>
        
        <Card class="detail-card">
          <p slot="title"><Icon type="md-create" /> 描述与备注</p>
          <div class="desc-section">
            <h4><Icon type="md-document" /> 资产描述</h4>
            <div class="desc-content" style="white-space: pre-wrap;">{{ detailData.description || '暂无描述' }}</div>
          </div>
          <div class="desc-section">
            <h4><Icon type="md-list" /> 备注信息</h4>
            <div class="desc-content">{{ detailData.remark || '暂无备注' }}</div>
          </div>
        </Card>
        
        <Card class="detail-card">
          <p slot="title"><Icon type="md-time" /> 处置历史记录</p>
          <div v-if="!detailData.records || detailData.records.length === 0" class="no-records">
            <Icon type="md-alert" size="20" />
            <span>暂无处置记录</span>
          </div>
          <div v-else class="records-list">
            <Timeline>
              <TimelineItem v-for="(record, index) in detailData.records" :key="index" :color="getTimelineColor(record.disposal_type)">
                <p class="record-time">{{ moment(record.disposal_time).format('YYYY-MM-DD HH:mm:ss') }}</p>
                <div class="record-content">
                  <p class="record-type"><Tag :color="getTypeTagColor(record.disposal_type)">{{ record.disposal_type }}</Tag></p>
                  <p class="record-dept"><BaseIcon icon="organization" /> 涉及部门：{{ getDepNameById(record.dep_id) || '-' }}</p>
                  <p class="record-operator"><Icon type="md-person" /> {{ getOperatorLabel(record.disposal_type) }}：{{ users.find(u => u.id === record.operator_id) ? users.find(u => u.id === record.operator_id).name : '-' }}</p>
                  <p class="record-created"><Icon type="md-create" /> 办理人：{{ users.find(u => u.id === record.created_by) ? users.find(u => u.id === record.created_by).name : '-' }}</p>
                  <p class="record-remark" v-if="record.remark"><Icon type="md-quote" /> 备注：{{ record.remark }}</p>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </Card>
      </div>
    </Drawer>

    <!-- 资产处置弹窗 -->
    <Modal v-model="showDisposalModal" :title="getDisposalTitle()" width="600" footer-hide>
      <Form ref="disposalForm" :model="disposalFormData" :rules="disposalRules" label-position="right" :label-width="100">
        <FormItem label="处置方式" prop="disposal_type">
          <RadioGroup v-model="disposalFormData.disposal_type">
            <Radio v-for="option in getDisposalTypeOptions()" :key="option.value" :label="option.value">{{ option.label }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="处置时间" prop="disposal_time">
          <DatePicker 
            v-model="disposalFormData.disposal_time" 
            type="datetime" 
            placeholder="请选择处置时间"
            style="width: 100%"
          />
        </FormItem>
        <FormItem label="涉及人" prop="operator_id">
          <BaseUserSelectGrouped v-model="disposalFormData.operator_id" :option="{ users }" />
        </FormItem>
        <FormItem label="涉及部门" prop="dep_id">
          <Select
            v-model="disposalFormData.dep_id"
            placeholder="请选择涉及部门"
            style="width: 100%"
          >
            <Option v-for="dep in deps" :key="dep.id" :value="dep.id">{{ dep.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="处置备注" prop="remark">
          <Input type="textarea" :rows="3" v-model="disposalFormData.remark" placeholder="请输入处置备注" />
        </FormItem>
        <FormItem label="处置凭证" prop="disposal_proof">
          <BaseFileUpload v-model="disposalFormData.disposal_proof" :limit="1" />
        </FormItem>
      </Form>
      <div class="modal-footer" style="width: 100%;">
        <div class="btn-group flex-wrap flex-center split5">
          <Button type="primary" @click="handleDisposalSubmit" :loading="disposalSubmitting">确定</Button>
          <Button @click="showDisposalModal = false">取消</Button>
        </div>
        
        <div class="records-history" style="padding:10px;border:1px solid var(--border);margin:5px;margin-top:20px;">
          <h3>处置历史记录</h3>
          <Spin v-if="detailLoading" size="large"></Spin>
          <div v-else-if="!currentAsset || !currentAsset.records || currentAsset.records.length === 0" class="no-records">
            暂无处置记录
          </div>
          <div v-else class="records-list">
            <Timeline>
              <TimelineItem v-for="(record, index) in currentAsset.records" :key="index" :color="getTimelineColor(record.disposal_type)">
                <p class="record-time">{{ moment(record.disposal_time).format('YYYY-MM-DD HH:mm:ss') }}</p>
                <div class="record-content">
                  <p class="record-type"><Tag :color="getTypeTagColor(record.disposal_type)">{{ record.disposal_type }}</Tag></p>
                  <p class="record-dept">涉及部门：{{ getDepNameById(record.dep_id) || '-' }}</p>
                  <p class="record-operator">{{ getOperatorLabel(record.disposal_type) }}：{{ users.find(u => u.id === record.operator_id) ? users.find(u => u.id === record.operator_id).name : '-' }}</p>
                  <p class="record-created">办理人：{{ users.find(u => u.id === record.created_by) ? users.find(u => u.id === record.created_by).name : '-' }}</p>
                  <p class="record-remark" v-if="record.remark">备注：{{ record.remark }}</p>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import * as XLSX from 'xlsx'
import moment from 'moment'

export default {
  name: 'FixedAssetsPage',
  data() {
    return {
      loading: false,
      submitting: false,
      showModal: false,
      showDetailDrawer: false,
      showDisposalModal: false,
      disposalSubmitting: false,
      detailLoading: false,
      currentAsset: null,
      detailData: {},
      lastLoadedAssetId: null,
      disposalFormData: {
        disposal_type: '',
        disposal_time: new Date(),
        operator_id: null,
        dep_id: null,
        remark: '',
        disposal_proof: ''
      },
      modalTitle: '新增资产',
      searchText: '',
      filters: {
        status: '',
        dep_id: null
      },
      page: 1,
      pagesize: 20,
      order: { key: 'created_at', type: 'desc' },
      assets: [],
      activeCell: null,
      total: 0,
      sum: {},
      formData: {
        asset_id: '',
        name: '',
        type: '',
        description: '',
        purchase_time: null,
        purchase_amount: 0,
        dep_id: null,
        user_id: null,
        remark: ''
      },
      rules: {
        asset_id: [
          { required: true, message: '请输入资产编号', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入资产名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请输入资产类型', trigger: 'blur' }
        ],
        purchase_time: [
          { required: true, message: '请选择购买时间', trigger: 'change' }
        ],
        purchase_amount: [
          { required: true, message: '请输入购买金额', trigger: 'blur' }
        ],
        dep_id: [
          { required: true, message: '请选择使用部门', trigger: 'change' }
        ],
        user_id: [
          { required: true, message: '请选择使用人', trigger: 'change' }
        ]
      },
      disposalRules: {
        disposal_type: [
          { required: true, message: '请选择处置方式', trigger: 'change' }
        ],
        disposal_time: [],
        operator_id: [],
        dep_id: [
          { required: true, message: '请选择涉及部门', trigger: 'change' }
        ]
      },
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 80,
        },
        {
          type: 'text',
          title: '资产编号',
          key: 'asset_id',
          width: 170,
        },
        {
          type: 'text',
          title: '资产类型',
          key: 'type',
          width: 120,
          render: (h, params) => {
            return h('div', {
              style: {
                padding: '0 5px',
                position: 'relative'
              },
              on: {
                mouseenter: () => {
                  if (!this.activeCell) {
                    params.row._showTypeEdit = true;
                  }
                },
                mouseleave: () => {
                  if (!this.activeCell || this.activeCell !== `type_${params.row.id}`) {
                    params.row._showTypeEdit = false;
                  }
                }
              }
            }, [
              params.row._showTypeEdit ? h('Select', {
                props: {
                  value: params.row.type,
                  size: 'small',
                  transfer: true
                },
                style: {
                  width: 'calc(100% - 10px)',
                  margin: '0 5px',
                  position: 'relative',
                  zIndex: 1000
                },
                on: {
                  'on-click': () => {
                    this.activeCell = `type_${params.row.id}`;
                  },
                  'on-change': (value) => {
                    this.handleTypeChange(params.row.id, value);
                    params.row._showTypeEdit = false;
                    this.activeCell = null;
                  },
                  'on-open-change': (visible) => {
                    console.log(visible)
                    if (!visible) {
                      setTimeout(() => {
                        params.row._showTypeEdit = false;
                        this.activeCell = null;
                      }, 500);
                    }else{
                      this.activeCell = `type_${params.row.id}`;
                    }
                  }
                }
              }, [
                h('Option', {
                  props: {
                    value: '台式电脑'
                  }
                }, '台式电脑'),
                h('Option', {
                  props: {
                    value: '笔记本电脑'
                  }
                }, '笔记本电脑'),
                h('Option', {
                  props: {
                    value: '屏幕'
                  }
                }, '屏幕'),
                h('Option', {
                  props: {
                    value: '打印机'
                  }
                }, '打印机')
              ]) : h('span', {
                style: {
                  display: 'inline-block',
                  padding: '0px 8px',
                  borderRadius: '4px',
                  backgroundColor: this.getTypeColor(params.row.type),
                  color: '#fff'
                }
              }, params.row.type)
            ])
          }
        },
        {
          type: 'text',
          title: '资产名称',
          key: 'name',
          minWidth: 300,
          render: (h, params) => {
            return h('div', {
              style: {
                display: 'flex',
                alignItems: 'center',
                
                cursor: 'pointer',
                color: '#2d8cf0',
                padding: '0 10px'
              },
              on: {
                click: () => {
                  this.showDetail(params.row)
                }
              }
            }, [
              h('Icon', {
                props: {
                  type: 'md-cube',
                  size: 16
                },
                style: {
                  marginRight: '5px'
                }
              }),
              h('span', {
                style: {
                  textDecoration: 'underline',
                  textAlign: 'left',
                }
              }, params.row.name)
            ])
          }
        },
         {
          type: 'text',
          title: '资产状态',
          key: 'status',
          width: 100,
          render: (h, params) => {
            return h('div', {
              style: {
                padding: '0 5px',
                position: 'relative'
              },
              on: {
                mouseenter: () => {
                  if (!this.activeCell) {
                    params.row._showStatusEdit = true;
                  }
                },
                mouseleave: () => {
                  if (!this.activeCell || this.activeCell !== `status_${params.row.id}`) {
                    params.row._showStatusEdit = false;
                  }
                }
              }
            }, [
              params.row._showStatusEdit ? h('Select', {
                props: {
                  value: params.row.status,
                  size: 'small',
                  transfer: true
                },
                style: {
                  width: 'calc(100% - 10px)',
                  margin: '0 5px',
                  position: 'relative',
                  zIndex: 1000
                },
                on: {
                  'on-click': () => {
                    this.activeCell = `status_${params.row.id}`;
                  },
                  'on-change': (value) => {
                    this.handleStatusChange(params.row.id, value);
                    params.row._showStatusEdit = false;
                    this.activeCell = null;
                  },
                  'on-open-change': (visible) => {
                    if (!visible) {
                      setTimeout(() => {
                        params.row._showStatusEdit = false;
                        this.activeCell = null;
                      }, 500);
                    }else{
                      this.activeCell = `status_${params.row.id}`;
                    }
                  }
                }
              }, [
                h('Option', {
                  props: {
                    value: '使用中'
                  }
                }, '使用中'),
                h('Option', {
                  props: {
                    value: '闲置中'
                  }
                }, '闲置中'),
                h('Option', {
                  props: {
                    value: '已报废'
                  }
                }, '已报废')
              ]) : h('span', {
                style: {
                  display: 'inline-block',
                  padding: '0px 8px',
                  borderRadius: '4px',
                  backgroundColor: this.getStatusColor(params.row.status),
                  color: '#fff'
                }
              }, params.row.status || '使用中')
            ])
          }
        },
        {
          type: 'time',
          title: '购买时间',
          key: 'purchase_time',
          width: 160,
          option: {
            type: 'datetime'
          }
        },
        {
          type: 'number',
          title: '购买金额',
          key: 'purchase_amount',
          width: 120,
          option: {
            sumable: true,
            formatter: val => this.formatMoney(val)
          }
        },
        {
          type: 'text',
          title: '使用部门',
          key: 'dep_id',
          width: 140,
          render: (h, { row }) => {
            return h('div', {
              style: {
                padding: '0 5px',
                position: 'relative'
              },
              on: {
                mouseenter: () => {
                  if (!this.activeCell) {
                    row._showDepEdit = true;
                  }
                },
                mouseleave: () => {
                  if (!this.activeCell || this.activeCell !== `dep_${row.id}`) {
                    row._showDepEdit = false;
                  }
                }
              }
            }, [
              row._showDepEdit ? h('Select', {
                props: {
                  value: row.dep_id,
                  size: 'small',
                  transfer: true
                },
                style: {
                  width: 'calc(100% - 10px)',
                  margin: '0 5px',
                  position: 'relative',
                  zIndex: 1000
                },
                on: {
                  'on-click': () => {
                    this.activeCell = `dep_${row.id}`;
                  },
                  'on-change': (value) => {
                    this.handleFieldChange(row.id, 'dep_id', value);
                    row._showDepEdit = false;
                    this.activeCell = null;
                  },
                  'on-open-change': (visible) => {
                    if (!visible) {
                      setTimeout(() => {
                        row._showDepEdit = false;
                        this.activeCell = null;
                      }, 500);
                    }else{
                      this.activeCell = `dep_${row.id}`;
                    }
                  }
                }
              },
              this.deps.map(d=>h('Option', {
                  props: {
                    value: d.id
                  }
                }, d.name)),
            
            ) : h('span', {
                style: {
                  lineHeight: '24px'
                }
              }, this.getDepNameById(row.dep_id) || '-')
            ])
          }
        },
        {
          type: 'text',
          title: '使用人',
          key: 'user_id',
          width: 120,
          render: (h, { row }) => {
            return h('div', {
              style: {
                padding: '0 5px',
                position: 'relative'
              },
              on: {
                mouseenter: () => {
                  if (!this.activeCell) {
                    row._showUserEdit = true;
                  }
                },
                mouseleave: () => {
                  if (!this.activeCell || this.activeCell !== `user_${row.id}`) {
                    row._showUserEdit = false;
                  }
                }
              }
            }, [
              row._showUserEdit ? h('BaseUserSelectGrouped', {
                props: {
                  value: row.user_id,
                  size: 'small',
                  transfer: true,
                  option: { users: this.users }
                },
                style: {
                  width: '100%',
                  position: 'relative',
                  zIndex: 1000
                },
                on: {
                  'on-focus': () => {
                    this.activeCell = `user_${row.id}`;
                  },
                  input: (value) => {
                    this.handleFieldChange(row.id, 'user_id', value);
                    row._showUserEdit = false;
                    this.activeCell = null;
                  },
                  'on-open-change': (visible) => {
                    if (!visible) {
                      setTimeout(() => {
                        row._showUserEdit = false;
                        this.activeCell = null;
                      }, 500);
                    }else{
                      this.activeCell = `user_${row.id}`;
                    }
                  }
                }
              }) : h('span', {
                style: {
                  lineHeight: '24px'
                }
              }, this.users.find(u => u.id === row.user_id)?.name || '-')
            ])
          }
        },
        {
          type: 'text',
          title: '备注',
          key: 'remark',
          width: 150
        },
        {
          type: 'time',
          title: '创建时间',
          key: 'created_at',
          width: 120,
        },
       
        {
          title: '操作',
          slot: 'action',
          width: 250,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'warning',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.handleDisposal(params.row);
                  }
                }
              }, '操作'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                on: {
                  click: () => {
                    this.handleEdit(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginLeft: '5px'
                },
                on: {
                  click: () => {
                    this.handleDelete(params.row)
                  }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  },
  computed: {
    deps() {
      return this.$store.getters['session/deps']
    },
    users() {
      return this.$store.getters['session/users']
    },
    depTreeData() {
      return this.buildDepTree(this.deps)
    }
  },
  mounted() {
    this.$api.afterLogin(()=>{
   
      this.$store.dispatch('session/getUsers')
      this.$store.dispatch('session/getDeps')
      this.getData(true)
    })
  },
  methods: {
    formatMoney(val) {
      return val ? `¥${val.toFixed(2)}` : '-'
    },
    
    handleSearch() {
      this.page = 1
      this.getData(true)
    },
    
    handleTableEvent(e) {
      switch(e.type) {
        case 'page-change':
          this.page = e.data
          this.getData(true)
          break
        case 'page-size-change':
          this.pagesize = e.data
          this.getData(true)
          break
        case 'sort':
          this.order = e.data
          this.page = 1
          this.getData(true)
          break
      }
    },
    
    async getData(forced = false) {
      this.loading = true;
      this.activeCell = null; // 重置独占状态
      
      try {
        const res = await this.$api.get('/oa/fixed_assets', {
          params: {
            q:'page',
            page: this.page,
            pagesize: this.pagesize,
            search: this.searchText,
              status: this.filters.status || undefined,
              dep_id: this.filters.dep_id || undefined,
            order: this.order.key,
            desc:this.order.type == 'desc',
            forced
          }
        })
        
        // 先清空数组，确保完全重新创建
        this.assets = [];
        
        // 使用nextTick确保视图更新后再设置新数据
        this.$nextTick(() => {
          this.assets = res.data.data.list.map(item => ({
            ...item,
            _showTypeEdit: false,
            _showStatusEdit: false,
            _showDepEdit: false,
            _showUserEdit: false
          }));
          this.total = res.data.data.count;
        });
      } catch (error) {
        this.$Message.error('获取数据失败');
      } finally {
        this.loading = false;
      }
    },
    
    handleAdd() {
      this.modalTitle = '新增资产'
      this.formData = {
        asset_id: '',
        name: '',
        type: '',
        description: '',
        purchase_time: null,
        purchase_amount: 0,
        dep_id: null,
        user_id: null,
        remark: ''
      }
      this.showModal = true
    },
    
    handleEdit(row) {
      this.modalTitle = '编辑资产'
      this.formData = { ...row }
      this.showModal = true
    },
    
    handleDelete(row) {
      this.$Modal.confirm({
        title: '确认删除',
        content: `确定要删除资产"${row.name}"吗？`,
        onOk: async () => {
          try {
            await this.$api.delete(`/oa/fixed_assets/${row.id}`)
            this.$Message.success('删除成功')
            this.getData(true)
          } catch (error) {
            this.$Message.error('删除失败')
          }
        }
      })
    },
    
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        this.submitting = true
        
        const method = this.formData.id ? 'put' : 'post'
        const url = this.formData.id ? `/oa/fixed_assets/${this.formData.id}` : '/oa/fixed_assets'
        
        await this.$api[method](url, this.formData)
        this.$Message.success(this.formData.id ? '更新成功' : '添加成功')
        this.showModal = false
        this.getData(true)
      } catch (error) {
        if (error.message) {
          this.$Message.error(error.message)
        }
      } finally {
        this.submitting = false
      }
    },
    
    exportExcel() {
      const data = this.assets.map(item => ({
        '资产编号': item.asset_id,
        '资产名称': item.name,
        '资产类型': item.type,
        '资产描述': item.description,
        '购买时间': moment(item.purchase_time).format('YYYY-MM-DD HH:mm:ss'),
        '购买金额': item.purchase_amount,
        '使用部门': this.getDepNameById(item.dep_id) || '-',
        '使用人': this.users.find(u => u.id === item.user_id)?.name || '-',
        '备注': item.remark,
        '创建时间': moment(item.created).format('YYYY-MM-DD HH:mm:ss')
      }))
      
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '固定资产列表')
      
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `固定资产列表_${moment().format('YYYYMMDD')}.xlsx`
      link.click()
      
      setTimeout(() => {
        URL.revokeObjectURL(link.href)
        link.remove()
      }, 500)
    },
    
    async handleTypeChange(id, value) {
      try {
        await this.$api.patch(`/oa/fixed_assets/${id}`, { type: value });
        // 更新本地数据
        const index = this.assets.findIndex(item => item.id === id);
        if (index !== -1) {
          this.assets[index].type = value;
        }
        this.$Message.success('更新成功');
      } catch (error) {
        this.$Message.error('更新失败');
      }
    },
    
    async handleFieldChange(id, field, value) {
      try {
        await this.$api.patch(`/oa/fixed_assets/${id}`, { [field]: value });
        // 更新本地数据
        const index = this.assets.findIndex(item => item.id === id);
        if (index !== -1) {
          this.assets[index][field] = value;
        }
        this.$Message.success('更新成功');
      } catch (error) {
        this.$Message.error('更新失败');
        throw error;
      }
    },
    
    async handleStatusChange(id, value) {
      try {
        await this.$api.patch(`/oa/fixed_assets/${id}`, { status: value });
        // 更新本地数据
        const index = this.assets.findIndex(item => item.id === id);
        if (index !== -1) {
          this.assets[index].status = value;
        }
        this.$Message.success('更新成功');
      } catch (error) {
        this.$Message.error('更新失败');
      }
    },
    
    showDetail(row) {
      // 先使用基础对象，确保records属性存在
      this.detailData = { ...row, records: [] }
      this.showDetailDrawer = true
      this.fetchAssetDetails(row.id)
    },
    buildDepTree(deps) {
      // 先构建一个映射表
      const depMap = new Map()
      deps.forEach(dep => {
        depMap.set(dep.id, {
          title: dep.name,
          value: dep.id,
          expand: true,  // 默认展开所有节点
          selected: false,  // 不要在这里设置selected，让Tree组件自己控制
          children: []
        })
      })
      
      // 构建树形结构
      const tree = []
      deps.forEach(dep => {
        const node = depMap.get(dep.id)
        if (dep.parent_id && depMap.has(dep.parent_id)) {
          // 如果有父节点，将当前节点添加到父节点的children中
          depMap.get(dep.parent_id).children.push(node)
        } else {
          // 如果没有父节点或父节点不存在，则作为根节点
          tree.push(node)
        }
      })
      
      return tree
    },
    
    getDepNameById(depId) {
      if (!depId) return ''
      const findDep = (tree) => {
        for (const node of tree) {
          if (node.value === depId) return node.title
          if (node.children && node.children.length) {
            const found = findDep(node.children)
            if (found) return found
          }
        }
        return null
      }
      return findDep(this.depTreeData)
    },

    handleModalDepSelect(selectedNodes) {
      if (selectedNodes.length > 0) {
        this.formData.dep_id = selectedNodes[0].value
       console.log(selectedNodes,this.formData)
      }
    },

    handleModalDepChange(value) {
      // 不需要这个方法了，因为我们在handleModalDepSelect中已经处理了值的同步
    },

    getTypeColor(type) {
      const colors = {
        '台式电脑': '#2d8cf0',
        '笔记本电脑': '#19be6b',
        '屏幕': '#ff9900',
        '打印机': '#ed4014'
      }
      return colors[type] || '#808695'
    },

    getStatusColor(status) {
      const colors = {
        '使用中': '#2d8cf0',
        '闲置中': '#ff9900',
        '已报废': '#ed4014'
      }
      return colors[status] || '#808695'
    },

    getDisposalActions(status) {
      // 根据资产状态返回可进行的操作
      switch(status || '使用中') {
        case '闲置中':
          return [
            { action: 'borrow', label: '借出' },
            { action: 'scrap', label: '报废' }
          ];
        case '使用中':
          return [
            { action: 'return', label: '归还' }
          ];
        case '已报废':
          return [
            { action: 'undoScrap', label: '撤销报废' }
          ];
        default:
          return [];
      }
    },

    getDisposalTypeOptions() {
      if (!this.currentAsset) return [];
      
      const status = this.currentAsset.status || '使用中';
      
      switch(status) {
        case '闲置中':
          return [
            { value: '领用', label: '领用' },
            { value: '报废', label: '报废' }
          ];
        case '使用中':
          return [
            { value: '归还', label: '归还' }
          ];
        case '已报废':
          return [
            { value: '撤销报废', label: '撤销报废' }
          ];
        default:
          return [];
      }
    },

    getDisposalTitle() {
      if (!this.currentAsset) return '资产处置';
      return `${this.currentAsset.name} - 资产处置`;
    },

    handleDisposal(row) {
      // 先初始化currentAsset为不含records的基础对象
      this.currentAsset = { ...row, records: [] };
      
      // 初始化处置表单数据
      this.disposalFormData = {
        disposal_type: '',
        disposal_time: new Date(),
        operator_id: row.user_id,
        dep_id: row.dep_id,
        remark: '',
        disposal_proof: ''
      };
      
      // 根据资产状态自动设置默认处置类型
      const status = row.status || '使用中';
      switch(status) {
        case '闲置中':
          this.disposalFormData.disposal_type = '领用';
          break;
        case '使用中':
          this.disposalFormData.disposal_type = '归还';
          break;
        case '已报废':
          this.disposalFormData.disposal_type = '撤销报废';
          break;
      }
      
      // 显示弹窗，然后异步加载完整数据
      this.showDisposalModal = true;
      this.fetchAssetDetails(row.id);
    },
    
    async fetchAssetDetails(id) {
      // 强制清除缓存的ID，确保每次都重新加载
      this.lastLoadedAssetId = null;
      this.detailLoading = true;
      
      try {
        const res = await this.$api.get(`/oa/fixed_assets/${id}`, {
          params: {
            related: 'records'
          }
        });
        
        const assetData = res.data.data;
        
        // 确保records字段存在
        if (!assetData.records) {
          assetData.records = [];
        }
        
        // 更新资产详情
        if (this.showDetailDrawer) {
          this.detailData = assetData;
        }
        
        // 更新当前资产
        if (this.showDisposalModal) {
          this.currentAsset = assetData;
        }
        
        // 记录最后加载的资产ID
        this.lastLoadedAssetId = id;
      } catch (error) {
        this.$Message.error('获取资产详情失败');
        // 确保即使失败也有基本数据
        if (this.showDetailDrawer && !this.detailData.records) {
          this.detailData.records = [];
        }
        if (this.showDisposalModal && !this.currentAsset.records) {
          this.currentAsset.records = [];
        }
      } finally {
        this.detailLoading = false;
      }
    },
    
    async handleDisposalSubmit() {
      try {
        await this.$refs.disposalForm.validate();
        this.disposalSubmitting = true;
        
        // 确定资产新状态
        let newStatus = this.currentAsset.status;
        switch(this.disposalFormData.disposal_type) {
          case '领用':
            newStatus = '使用中';
            break;
          case '归还':
            newStatus = '闲置中';
            break;
          case '报废':
            newStatus = '已报废';
            break;
          case '撤销报废':
            newStatus = '闲置中';
            break;
        }
        
        // 调用API提交处置记录
        await this.$api.post(`/oa/fixed_assets/${this.currentAsset.id}/records`, this.disposalFormData);
        
        // 更新资产状态
        await this.$api.patch(`/oa/fixed_assets/${this.currentAsset.id}`, { status: newStatus });
        
        // 更新本地数据
        const index = this.assets.findIndex(item => item.id === this.currentAsset.id);
        if (index !== -1) {
          this.assets[index].status = newStatus;
        }
        
        this.$Message.success('处置成功');
        this.showDisposalModal = false;
      } catch (error) {
        if (error.message) {
          this.$Message.error(error.message);
        } else {
          this.$Message.error('处置失败');
        }
      } finally {
        this.disposalSubmitting = false;
      }
    },
    getTimelineColor(disposalType) {
      const colors = {
        '领用': 'blue',
        '归还': 'green',
        '报废': 'red',
        '撤销报废': 'yellow'
      }
      return colors[disposalType] || 'blue'
    },
    
    getTypeTagColor(disposalType) {
      const colors = {
        '领用': 'primary',
        '归还': 'success',
        '报废': 'error',
        '撤销报废': 'warning'
      }
      return colors[disposalType] || 'default'
    },
    
    getOperatorLabel(disposalType) {
      switch(disposalType) {
        case '领用':
          return '领用人';
        case '归还':
          return '交还人';
        case '报废':
        case '撤销报废':
          return '处理人';
        default:
          return '涉及人';
      }
    }
  }
}
</script>

<style lang="less" scoped>
 .detail-card {
    margin-bottom:20px;
  }


  .detail-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      .detail-item {
        padding: 10px;
        background: #f9f9f9;
        border-radius: 4px;
        border-left: 3px solid #2d8cf0;

        strong {
          display: flex;
          align-items: center;
          color: #17233d;
          margin-bottom: 8px;
          font-size: 14px;
          margin-right:10px;
          
          i {
            margin-right: 5px;
          }
        }

        span {
          color: #515a6e;
          font-size: 15px;
        }
      }
    }

    .modal-footer {
    margin-top: 20px;
    
    .btn-group {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      
      .ivu-btn {
        margin: 0 10px;
      }
    }
    
    .records-history {
      border-top: 1px solid #e8e8e8;
      padding-top: 20px;
      
      h3 {
        margin-bottom: 15px;
        font-size: 16px;
        color: #17233d;
      }
      
      .no-records {
        text-align: center;
        color: #808695;
        padding: 20px 0;
      }
      
      .records-list {
        max-height: 300px;
        overflow-y: auto;
        
        .record-time {
          font-weight: bold;
          color: #2d8cf0;
        }
        
        .record-remark {
          color: #808695;
          font-style: italic;
        }
      }
    }
  }


.fixed-assets-page {
  padding: 20px;
 
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    h2 {
      margin: 0;
      font-size: 24px;
      color: #17233d;
    }
  }
  
  .assets-card {
    /deep/ .ivu-card-body {
      padding: 20px;
    }
  }
  
  .table-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    
    .left {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  
 
  
  /deep/ .ivu-btn {
    margin-right: 8px;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  /deep/ .hover-edit {
    position: relative;
    min-height: 24px;
    
    .text-content {
      display: block;
      line-height: 24px;
    }
    
    .edit-component {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    
    &:hover {
      .text-content {
        display: none;
      }
      
      .edit-component {
        display: block;
      }
    }
  }

  .detail-content {
    padding: 0;

    .detail-header {
      margin-bottom: 20px;
      padding: 15px;
      background: #f8f8f9;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .asset-id-box {
        display: flex;
        align-items: center;
        
        .asset-id {
          font-size: 18px;
          font-weight: bold;
          margin-left: 10px;
          color: #17233d;
        }
      }
      
      .status-tag {
        font-size: 14px;
        padding: 4px 8px;
      }
    }
    
    .detail-card {
      margin-bottom: 20px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
      
      /deep/ .ivu-card-head {
        padding: 12px 16px;
        background: #f8f8f9;
        
        p {
          display: flex;
          align-items: center;
          
          i {
            margin-right: 8px;
          }
        }
      }
      
      /deep/ .ivu-card-body {
        padding: 16px;
      }
    }

    .detail-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      .detail-item {
        padding: 10px;
        background: #f9f9f9;
        border-radius: 4px;
        border-left: 3px solid #2d8cf0;

        strong {
          display: flex;
          align-items: center;
          color: #17233d;
          margin-bottom: 8px;
          font-size: 14px;
          margin-right:10px;
          
          i {
            margin-right: 5px;
          }
        }

        span {
          color: #515a6e;
          font-size: 15px;
        }
      }
    }

    .desc-section {
      margin-bottom: 16px;
      
      h4 {
        display: flex;
        align-items: center;
        color: #17233d;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: bold;
        
        i {
          margin-right: 5px;
        }
      }
      
      .desc-content {
        background: #f9f9f9;
        padding: 12px;
        border-radius: 4px;
        color: #515a6e;
        min-height: 60px;
        white-space: pre-wrap;
        line-height: 1.6;
      }
    }

    .no-records {
      text-align: center;
      color: #808695;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      i {
        margin-bottom: 10px;
        color: #c5c8ce;
      }
    }
    
    .records-list {
      max-height: 350px;
      overflow-y: auto;
      padding: 10px;
      
      .record-time {
        font-weight: bold;
        color: #2d8cf0;
        margin-bottom: 5px;
      }
      
      .record-content {
        background: #f9f9f9;
        padding: 12px;
        border-radius: 4px;
        margin-bottom: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      }
      
      .record-type {
        margin-bottom: 10px;
      }
      
      .record-operator, .record-dept, .record-created {
        margin: 8px 0;
        color: #515a6e;
        display: flex;
        align-items: center;
        
        i {
          margin-right: 5px;
          color: #808695;
        }
      }
      
      .record-remark {
        color: #808695;
        font-style: italic;
        margin-top: 10px;
        border-top: 1px dashed #e8e8e8;
        padding-top: 10px;
        display: flex;
        align-items: flex-start;
        
        i {
          margin-right: 5px;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
