<style lang="less">
.pdf-export{
  .markdown-body h1{
    margin-top:0 !important;
  }
}
</style>
<template>
  <App class="flex-wrap">
    <AppBar style="width:100%">
      <AppTitle icon="logo-angular" name="AI 实验室" />
    </AppBar>
    <AppClient>
      <div class="flex-wrap flex-between" style="height:50px;padding:0 10px;background:linear-gradient(to right,#fff,var(--border))">
        <div class="flex-wrap split5"> <Select v-model="project_id" filterable style="width:300px" placeholder="选择项目" @on-change="cached=false">
          <template v-for="pr in projects">
          <Option 
            :value="pr.id"
            :key="pr.id"
            :label="pr.name"
          >
          {{ pr.name }}
            <Tag>{{ pr.finished?'已完成':'进行中' }}</Tag>
            <Tag>{{ pr.group}}</Tag>
          </Option>
        </template>
        </Select>
       
        <Select v-model="view" style="width:140px" placeholder="选择视图">
          <Option
            v-for="p in views"
            :value="p.key"
            :key="p.key"
          >
          {{ p.name }}
          </Option>
        </Select>
        <Select v-model="model" style="width:140px" placeholder="选择模型">
          <Option
            v-for="p in models"
            :value="p.key"
            :key="p.key"
          >
          {{ p.name }}
          </Option>
        </Select>
        </div>
        <Button @click="exportPDF">导出pdf</Button>
       
      </div>
      <div class="flex-wrap flex-between" style="height:calc(100% - 50px);border-top:1px solid var(--border);position:relative;padding:10px;width:100%;overflow:hidden;">
        <div style="border:1px dashed var(--border);height:calc(100%);flex-shrink:1;flex-grow:1;">
          <Input 
            type="textarea"
            v-model="userText"
            :rows="8"
            style="width:100%;display:block"
            placeholder="请输入您的需求，尽可能详细，比如：帮我设计一个监理用表:瓶装液化气安全检查"
          />
          <div class="flex-wrap flex-between">
            <div> <Button
            style="margin:10px 0;"
            @click="getProjectData">获取数据</Button>
           文本长度:{{ aiText.length }}</div>
           <div class="flex-wrap">
            <Button
            type="primary"
            @click="CreateByAI"
            :loading="ai_executings">{{ai_executings?'正在努力生成,请耐心等待20-30s...':(cached?'查看报告':'生成报告')}}</Button>
            <Button
            style="margin:10px"
            v-if="cached"
            @click="CreateByAI(true)"
            :loading="ai_executings">重新生成</Button>
          </div>
          </div>
         
         
          <div class="flex-wrap flex-center pdf-export" style="height:calc(100% - 220px);background:#fff;position:relative;overflow-y:auto;padding:10px;">
            <Input 
            type="textarea"
            v-model="aiText"
            :rows="28"
            style="width:100%;height:100%;display:block"
            placeholder="请输入您的需求，尽可能详细，比如：帮我设计一个监理用表:瓶装液化气安全检查"
          />
          </div>
        </div>
        <div class="pdf-export" style="width:900px;flex-shrink:0;background:#fff;border:1px solid var(--border);height:100%;overflow-y:auto;margin-left:10px;padding:20px 40px;">
        
          <BaseMarkdownView  ref="content" :value="result" />
        </div>
      </div>
    </AppClient>
  </App>
</template>

<script>
import axios from 'axios'
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppTitle from '@/components/app/title'
import AppClient from '@/components/app/client'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
export default {
  data(){
    return {
      run:{},
      steps:[],
      messages:{},
      models:[],
      aiText:"",
      model:'douyin',
      data:"",
      userText:"",
      ai_executings:false,
      result:"",
      tasks:[],
      projects:[],
      project_id:'pid6248348fa092b',
      view:'recent',
      cached:false,
      views:[
      {
        key:'all',
        name:'全部工作',
      },{
      key:'finished',
      name:'已完工作'
    },{
      key:'allToDo',
      name:'未完工作'
    },{
      key:'recent',
      name:"近期工作"
    },{
      key:'myTodo',
      name:'我的待办'
    },{
      key:'today',
      name:'今日动态'
    },{
      key:'yestoday',
      name:'昨日动态'
    },{
      key:'theDayBeforeYestoday',
      name:'前日动态'
    },{
      key:'aboutMe',
      name:'我的工作'
    },{
      key:'alert',
      name:'预警工作'
    },{
      key:'nonActivation',
      name:'尚未启动'
     }],
    }
  },
 
  components:{App,AppBar,AppClient,AppTitle},
  mounted(){
    setTimeout(()=>{
      this.getProjects()
      this.getModels()
    },500)
  },
  methods:{
    getModels(){
      this.$api.get('ai/parse?q=models').then(res=>{
        this.models = res.data.data
      })
    },
    getProjects(){
      this.$api.get('logined_zzl/projects').then(res=>{
        this.projects = res.data.data
      })
    },
    exportPDF(){
      let project = this.projects.find(p=>p.id == this.project_id)
      const opt = {
        margin:1,
        filename:     `${project.name}_${this.view}_${moment().format("YYYYMMDD")}.pdf`,
        image:{ type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 }, // 提高清晰度
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } 
      }
      if(this.result)
        html2pdf().set(opt).from(this.$refs.content.$el).save()
    },
    getProjectData(){
      let project = this.projects.find(p=>p.id == this.project_id)
      this.$api.get(`logined_zzl/tasks?view=${this.view}&pid=${this.project_id}`).then(res=>{
        this.tasks = res.data.data
        let aiText = `今天是${moment().format('YYYY-MM-DD')}。项目名称是[${project.name}],以下是用以分析的工作数据，格式为[阶段,工作模块,任务名称,最近记录,负责人,任务状态]:`
        let taskText = this.tasks.map(v=>{
          return [v.stage,v.workPackage,v.node,v.newTracking || '无',v.executor,v.finishStatus?.text]
        })
        aiText += taskText.map(v=>JSON.stringify(v)).join(";")
        this.aiText = aiText
      })
    },
    CreateByAI(forced){
      if(this.aiText.length < 10){
        this.Error("请先点击【获取数据】，否则没有数据支持无法生成")
        return
      }
      if(this.aiText.length > 55000){
        this.Error("文本内容过大(暂时只支持50000字以内的处理)，建议拆分处理")
        return
      }
        this.ai_executings = true
        let parser = "chat"
        fetch(this.$api.defaults.baseURL+`/ai/parse/${parser}`, {
            method:"POST",
            body:JSON.stringify({
                "content":"用户额外需求:"+this.userText + ";数据="+this.aiText,
                "stream":true,
                "cached_id":this.project_id + '_' + parser,
                "model":this.model,
                 "forced":forced?1:0
            }),headers:{
                ...this.$api.defaults.headers,
                'Content-Type':'application/json'
            }}).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              response.json().then(res => {
                this.result = res.data;
                this.ai_executings = false;
                this.cached = true
              }).catch(err => {
                  this.ai_executings = false;
              });
              return
            }
            var that = this
            const reader = response.body.getReader();  // 获取流读取器
            console.log(reader)
            const decoder = new TextDecoder();  // 创建解码器
            let result = '';
            const ReadStream = ()=>{
                reader.read().then(({done,value})=>{
                    if(done){
                      console.log('done')
                        this.ai_executings = false
                        return
                    }
                    const decodedText = decoder.decode(value, { stream: true });
                    console.log(decodedText)
                    result += decodedText
                    this.result = result
                    this.moveAITextareaDown()
                    ReadStream()
                })
                
            }
            

           ReadStream()
        })
        .catch(e => {
            this.Error(e);
            this.ai_executings = false;
        });
        },
    moveAITextareaDown(){
        if(this.atd_timer)
            return
        this.atd_timer = setTimeout(()=>{
            if(this.$refs.ai_input){
                let inputElement = this.$refs.ai_input.$el.querySelector('textarea')
                this.$nextTick(()=>{
                    inputElement.scrollTop = inputElement.scrollHeight;
                })
            }
            delete this.atd_timer
        },500)
    },
    chat(){
       this.$ai.post("assistants/asst_7zEHmB2fxAgcP67FLe1M5R29/threads",{
        messages:[{role:'user',content:'如何创建一个30层的直跑楼梯'}]
       }).then(res=>{
        this.run = res.data.data
        if(this.run.status !== 'complete'){
          setTimeout(()=>{
            this.Refresh()
          },1000)
        }
      })
    },
    RefreshSteps(){
      for(let i=0;i<this.steps.length;i++){
        let step = this.steps[i]
        if(step.status == 'in_progress'){
          step.content = "Answer:"
          this.RefreshStep(i)
        }
      }
    },
    RefreshStep(i){
      let step = this.steps[i]
      this.$ai.get(`runs/${this.run.id}/steps/${step.id}?thread_id=${this.run.thread_id}`, { responseType: 'stream'  }).then(response=>{
     
        if (response && typeof response.pipe === 'function') {
            response.on('end', () => {
              console.log('end');
            });

            response.on('data', (chunk) => {
              console.log("data:", chunk.toString('utf-8'));
            });
          }
      })
    },
    RefreshMessage(message_id){
      this.messages[message_id] = {content:""}
      this.$ai.get(`threads/${this.run.thread_id}/message/${message_id}`, { responseType: 'stream',stream:true }).then(stream=>{
         
        if(stream && stream.data){
        stream.data.on('end',()=>{
          console.log('end')
        })
        stream.data.on('data',(chunk)=>{
          this.messages[message_id].content += chunk.toString('utf-8')
          console.log("text:",chunk.toString('utf-8'))
        })
        }
      })
    },
    Refresh(){
      if(this.run && this.run.id)
        this.$ai.get(`runs/${this.run.id}?thread_id=${this.run.thread_id}`).then(res=>{
          let run = res.data.data
          this.run.status = run.status
          if(run.steps)
            this.steps = run.steps
          if(this.run.status == 'queue'){
            setTimeout(()=>{
              this.Refresh()
            },1000)
          }else if(this.run.status == 'in_progress'){
            this.RefreshSteps()
          }
        })
    }
  }
}
</script>

<style>

</style>