import { PolylineEdge, PolylineEdgeModel } from '@logicflow/core';

// 自定义审批边模型
class ApprovalEdgeModel extends PolylineEdgeModel {
  // 初始化边数据（设置默认属性）
  initEdgeData(data) {
    super.initEdgeData(data);
    
    // 设置默认属性（若未传入）
    if (!this.properties || Object.keys(this.properties).length === 0) {
      this.properties = {
        type: 'submit', // 边类型：submit（提交）/reject（驳回）
        condition: '',  // 条件表达式
        status: 'pending' // 状态：pending（待处理）/approved（已通过）
      };
    }

    const typeNames = {
      submit:'提交',
      reject:'拒绝',
    }
    
    // 设置边文本位置
    this.text = {
      value: typeNames[this.properties.type] || '',
      x: 0,
      y: 0,
      draggable: false
    };
  }

  // 动态计算边样式
  getEdgeStyle() {
    const style = super.getEdgeStyle();
    const { type, status } = this.properties;

    // 根据类型设置颜色和箭头
    switch (type) {
      case 'submit':
        style.stroke = '#1890ff'; // 蓝色
        style.arrow = 'block';
        break;
      case 'reject':
        style.stroke = '#ff4d4f'; // 红色
        style.arrow = 'hollow'; // 空心箭头
        break;
      default:
        style.stroke = '#333';
    }

    // 根据状态设置虚线
    if (status === 'pending') {
      style.strokeDasharray = '5 5'; // 虚线
    } else {
      style.strokeDasharray = 'none'; // 实线
    }

    return style;
  }

  // 自定义边文本样式（可选）
  getTextStyle() {
    const style = super.getTextStyle();
    style.fontSize = 12;
    style.color = '#666';
    return style;
  }
}

// 注册边类型（需在 LogicFlow 初始化时调用）
export default {
  type: 'approval-edge', // 唯一类型标识
  view: PolylineEdge,    // 使用默认的折线视图
  model: ApprovalEdgeModel
};