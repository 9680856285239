<template>
  <div>
    <div class="search-bar">
      <Input v-model="searchQuery" placeholder="搜索模块名称或描述" clearable style="width: 300px" />
      <Button type="primary" @click="handleSearch" style="margin-left: 10px">搜索</Button>
      <Button type="success" @click="handleAdd" style="margin-left: 10px">新建模块</Button>
    </div>

    <div class="group-filter">
      <div 
        class="group-item" 
        :class="{'active': currentGroup === 'all'}" 
        @click="handleGroupChange('all')"
      >
        全部
        <span class="count">{{ modules.length }}</span>
      </div>
      <div 
        class="group-item" 
        :class="{'active': currentGroup === ''}" 
        @click="handleGroupChange('')"
      >
        未分组
        <span class="count">{{ getGroupModuleCount('') }}</span>
      </div>
      <div 
        v-for="group in availableGroups" 
        :key="group" 
        class="group-item" 
        :class="{'active': currentGroup === group}"
        @click="handleGroupChange(group)"
      >
        {{ group }}
        <span class="count">{{ getGroupModuleCount(group) }}</span>
      </div>
    </div>

    <div class="table-wrapper">
      <BaseTable
        :columns="columns"
        :data="filteredModules"
        :loading="loading"
        border
        @event="handleTableEvent"
      ></BaseTable>
    </div>

    <Modal
      v-model="modalVisible"
      :title="modalTitle"
      @on-ok="handleSubmit"
      :loading="confirmLoading"
    >
      <Form ref="formRef" :model="formData" :rules="rules">
        <div class="form-section">
          <h3>基本信息</h3>
          <div class="form-row">
            <FormItem label="名称" name="name" class="form-item">
              <Input v-model="formData.name" placeholder="请输入模块名称" />
            </FormItem>
            <FormItem label="分组" name="group" class="form-item">
              <Select v-model="formData.group" filterable allow-create style="width: 100%">
                <Option value="" key="empty">未分组</Option>
                <Option v-for="group in availableGroups" :value="group" :key="group">{{ group }}</Option>
              </Select>
            </FormItem>
          </div>
          <div class="form-row">
            <FormItem label="版本" name="version" class="form-item">
              <InputNumber v-model="formData.version" :min="1" style="width: 100%" />
            </FormItem>
            <FormItem label="图标" name="icon" class="form-item">
              <div class="icon-select-wrapper">
                <BaseIconSelect v-model="formData.icon" selectable />
              </div>
            </FormItem>
          </div>
          <FormItem label="描述" name="description">
            <Input type='textarea' v-model="formData.description" :rows="4" placeholder="请输入模块描述" />
          </FormItem>
        </div>

        <div class="form-section">
          <h3>路由配置</h3>
          <FormItem label="Base URL" name="base_url">
            <Input v-model="formData.base_url" placeholder="请输入基础URL" />
          </FormItem>
          <FormItem label="URL" name="url">
            <Input v-model="formData.url" placeholder="请输入访问URL" />
          </FormItem>
        </div>

        <div class="form-section">
          <h3>权限设置</h3>
          <div class="form-row">
            <FormItem label="私有" name="private" class="form-item">
              <i-switch v-model="formData.private" />
            </FormItem>
            <FormItem label="开发中" name="developing" class="form-item">
              <i-switch v-model="formData.developing" />
            </FormItem>
          </div>
          <div class="form-row">
            <FormItem label="公开权限等级" name="plevel" class="form-item">
              <BaseSelect v-model="formData.plevel" :options="plevelOptions" value-type="object" style="width: 100%" />
            </FormItem>
            <div class="form-item"></div>
          </div>
        </div>
      </Form>
    </Modal>

    <!-- 授权管理对话框 -->
    <Modal
      v-model="permissionModalVisible"
      title="授权管理"
      width="700"
    >
      <div class="perm-header">
        <h3>{{ currentModule ? currentModule.name : '' }} - 用户授权</h3>
        <div class="perm-add">
          <BaseUserSelectGrouped v-model="selectedUserId" placeholder="选择用户" style="width: 300px; margin-right: 10px;" />
          <Button type="primary" @click="handleAddPermission" :disabled="!selectedUserId">添加授权</Button>
        </div>
      </div>

      <Table :columns="permissionColumns" :data="permissions" :loading="permissionLoading">
        <template slot="action" slot-scope="{ row }">
          <Button type="error" size="small" @click="handleDeletePermission(row)" style="color: #fff">删除</Button>
        </template>
      </Table>

      <div slot="footer">
        <Button @click="permissionModalVisible = false">关闭</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modules: [],
      searchQuery: '',
      currentGroup: 'all',
      loading: false,
      confirmLoading: false,
      modalVisible: false,
      modalTitle: '添加模块',
      currentId: null,
      page: 1,
      pageSize: 20,
      formData: this.getInitialFormData(),
      
      // 授权相关
      permissionModalVisible: false,
      currentModule: null,
      permissions: [],
      permissionLoading: false,
      selectedUserId: null,
      permissionColumns: [
        { title: '授权用户', key: 'client_id', width: 160, render:(h,{row})=>{
          return h('BaseNameBoard',{props:{uid:row.client_id}})
        } },
        { title: '授权时间', key: 'created_at', minWidth: 200 },
        { 
          title: '操作', 
          slot: 'action',
          width: 100,
          align: 'center'
        }
      ],
      
      columns: [
        {type:'index',width:80,title:"序号"},
        { 
          title: '分组', 
          key: 'group',
          render: (h, params) => {
            if (!params.row.group || params.row.group.trim() === '') {
              return h('span', {
                style: {
                  color: '#999',
                  fontStyle: 'italic'
                }
              }, '未分组');
            }
            return h('Tag', {
              props: {
                color: 'blue'
              }
            }, params.row.group);
          }
        },
        { 
          title: '图标', 
          key: 'icon',
          width:100,
          sortable:false,
          render: (h, params) => {
            return h('div', {
              style: {
                textAlign: 'center',
                padding: '4px',
                background: '#f5f5f5',
                borderRadius: '4px',
                width: '36px',
                margin: '0 auto'
              }
            }, [
              h('BaseIcon', {
                props: {
                  icon: params.row.icon
                },
                style: {
                  fontSize: '20px'
                }
              })
            ]);
          }
        },
        { 
          title: '名称', 
          key: 'name',
          render: (h, params) => {
            return h('div', [
              h('strong', params.row.name)
            ]);
          }
        },
        { 
          title: '开发中', 
          key: 'developing',
          width: 90,
          align: 'center',
          render: (h, params) => {
            if (!params.row.developing) return h('span', { style: { color: '#ccc' } }, '否');
            return h('Tag', {
              props: {
                color: 'orange'
              }
            }, '开发中');
          }
        },
        { 
          title: '描述', 
          key: 'description',
          render: (h, params) => {
            if (!params.row.description) return h('span', { style: { color: '#ccc' } }, '暂无描述');
            return h('Tooltip', {
              props: {
                content: params.row.description,
                maxWidth: 300
              }
            }, [
              h('span', params.row.description.length > 30 ? 
                params.row.description.substring(0, 30) + '...' : 
                params.row.description)
            ]);
          }
        },
        { 
          title: 'Base URL', 
          key: 'base_url',
          render: (h, params) => {
            if (!params.row.base_url) return h('span', { style: { color: '#ccc' } }, '-');
            return h('span', {
              style: {
                fontFamily: 'monospace',
                background: '#f5f5f5',
                padding: '2px 4px',
                borderRadius: '3px'
              }
            }, params.row.base_url);
          }
        },
        { 
          title: 'URL', 
          key: 'url',
          render: (h, params) => {
            if (!params.row.url) return h('span', { style: { color: '#ccc' } }, '-');
            return h('span', {
              style: {
                fontFamily: 'monospace',
                background: '#f5f5f5',
                padding: '2px 4px',
                borderRadius: '3px',
                color: '#1890ff'
              }
            }, params.row.url);
          }
        },
        { 
          title: '版本', 
          key: 'version',
          render: (h, params) => {
            return h('Tag', {
              props: {
                color: 'green'
              }
            }, 'v' + params.row.version);
          }
        },
        { 
          title: '状态', 
          key: 'status',
          render: (h, params) => {
            const badges = [];
            
            if (params.row.private) {
              badges.push(h('Tag', {
                props: {
                  color: 'red'
                },
                style: {
                  marginRight: '5px'
                }
              }, '私有'));
            }
            
            // 添加权限等级标签
            const level = this.plevelOptions.find(o => o.id === params.row.plevel);
            const levelColors = {
              0: 'green',
              1: 'blue',
              2: 'purple', 
              3: 'magenta'
            };
            
            if (level) {
              badges.push(h('Tag', {
                props: {
                  color: levelColors[params.row.plevel] || 'default'
                }
              }, level.name));
            }
            
            return h('div', badges);
          }
        },
        { type:'time', title: '创建时间', key: 'created_at' },
        {
          title: '操作',
          key: 'action',
          fixed:'right',
          width:200,
          render: (h, params) => {
            const buttons = [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color: '#fff'
                },
                on: {
                  click: () => {
                    this.handleEdit(params.row);
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  color: '#fff'
                },
                on: {
                  click: () => {
                    this.handleDelete(params.row);
                  }
                }
              }, '删除')
            ];
            
            // 为私有模块添加授权按钮
            if (params.row.private) {
              buttons.splice(1, 0, h('Button', {
                props: {
                  type: 'warning',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color: '#fff'
                },
                on: {
                  click: () => {
                    this.handlePermissions(params.row);
                  }
                }
              }, '授权'));
            }
            
            return h('div', buttons);
          }
        }
      ],
      plevelOptions: [
        { name: '全部用户', id: 0 },
        { name: '企业用户', id: 1 },
        { name: '企业管理员', id: 2 },
        { name: '超级管理员', id: 3 }
      ],
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择图标', trigger: 'change' }]
      }
    };
  },
  computed: {
    availableGroups() {
      // 获取所有非空的唯一分组
      const groups = [...new Set(this.modules
        .map(item => item.group)
        .filter(group => group && group.trim() !== ''))];
      
      return groups.sort();
    },
    filteredModules() {
      let result = [...this.modules];
      
      // 先按分组筛选
      if (this.currentGroup !== 'all') {
        if (this.currentGroup === '') {
          // 筛选未分组的数据
          result = result.filter(module => !module.group || module.group.trim() === '');
        } else {
          // 筛选特定分组的数据
          result = result.filter(module => module.group === this.currentGroup);
        }
      }
      
      // 再按搜索词筛选
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        result = result.filter(module => 
          (module.name && module.name.toLowerCase().includes(query)) || 
          (module.description && module.description.toLowerCase().includes(query))
        );
      }
      
      return result;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getGroupModuleCount(group) {
      if (group === '') {
        return this.modules.filter(m => !m.group || m.group.trim() === '').length;
      }
      return this.modules.filter(m => m.group === group).length;
    },
    handleGroupChange(group) {
      this.currentGroup = group;
      this.page = 1; // 重置到第一页
    },
    getInitialFormData() {
      return {
        name: '新模块',
        icon: 'apps',
        description: '',
        group: '',
        base_url: '',
        url: '/core/new',
        version: 1,
        private: false,
        developing: false,
        plevel: 0
      };
    },
    handleSearch() {
      this.page = 1; // 重置到第一页
    },
    async fetchData() {
      this.loading = true;
      try {
        const response = await this.$api.get('/admin/mods');
        this.modules = response.data.data;
      } catch (error) {
        this.$Message.error('获取模块列表失败');
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    handleTableEvent(e) {
      const { type, data } = e;
      switch (type) {
        case 'select':
          this.currentId = data;
          break;
        case 'page-change':
          this.page = data;
          break;
        case 'page-size-change':
          this.pageSize = data;
          break;
      }
    },
    handleAdd() {
      this.modalTitle = '添加模块';
      this.formData = this.getInitialFormData();
      this.currentId = null;
      this.modalVisible = true;
    },
    handleEdit(record) {
      this.modalTitle = '编辑模块';
      this.formData = { ...record };
      this.currentId = record.id;
      this.modalVisible = true;
    },
    async handleDelete(record) {
      try {
        await this.$api.delete(`/admin/mods/${record.id}`);
        this.$Message.success('删除成功');
        this.fetchData();
      } catch (error) {
        this.$Message.error('删除失败');
        console.error(error);
      }
    },
    async handleSubmit() {
      try {
        await this.$refs.formRef.validate();
        this.confirmLoading = true;
        
        if (this.currentId) {
          // 更新
          await this.$api.patch(`/admin/mods/${this.currentId}`, this.formData);
          this.$Message.success('更新成功');
        } else {
          // 新增
          await this.$api.post('/admin/mods', this.formData);
          this.$Message.success('添加成功');
        }
        
        this.modalVisible = false;
        this.fetchData();
      } catch (error) {
        this.$Message.error(error.message || '提交失败');
        console.error(error);
      } finally {
        this.confirmLoading = false;
      }
    },
    async handlePermissions(record) {
      this.currentModule = record;
      this.permissionModalVisible = true;
      this.selectedUserId = null;
      
      await this.fetchPermissions();
    },
    async fetchPermissions() {
      if (!this.currentModule) return;
      
      this.permissionLoading = true;
      try {
        const response = await this.$api.get(`/admin/permissions?target_id=${this.currentModule.id}&target_type=module`);
        this.permissions = response.data.data || [];
      } catch (error) {
        this.$Message.error('获取授权信息失败');
        console.error(error);
      } finally {
        this.permissionLoading = false;
      }
    },
    async handleAddPermission() {
      if (!this.selectedUserId || !this.currentModule) return;
      
      try {
        await this.$api.post('/admin/permissions', {
          target_id: this.currentModule.id,
          target_type: 'module',
          client_type: 'user',
          client_id: this.selectedUserId
        });
        
        this.$Message.success('添加授权成功');
        this.selectedUserId = null;
        this.fetchPermissions();
      } catch (error) {
        this.$Message.error('添加授权失败');
        console.error(error);
      }
    },
    async handleDeletePermission(permission) {
      try {
        await this.$api.delete(`/admin/permissions/${permission.id}`);
        this.$Message.success('删除授权成功');
        this.fetchPermissions();
      } catch (error) {
        this.$Message.error('删除授权失败');
        console.error(error);
      }
    }
  }
};
</script>

<style>
.search-bar {
  border-bottom:1px solid var(--border);
  padding: 15px;
  background: #fff;
  display: flex;
  align-items: center;
}

.group-filter {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 4px;
  padding: 10px 15px;
}

.group-item {
  padding: 5px 12px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  position: relative;
  margin-bottom: 5px;
}

.group-item:hover {
  background: #f0f0f0;
}

.group-item.active {
  background: #e6f7ff;
  color: #1890ff;
  font-weight: 500;
}

.group-item .count {
  font-size: 12px;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 0 6px;
  margin-left: 5px;
  color: #666;
}

.group-item.active .count {
  background: #1890ff;
  color: #fff;
}

.table-wrapper {
  position: relative;
  height: calc(100vh - 160px);
  overflow: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}


.form-section {
  margin-bottom: 20px;
}

.form-section h3 {
  font-size: 16px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.form-row {
  display: flex;
  margin: 0 -8px;
}

.form-item {
  padding: 0 8px;
  flex: 1;
}

.perm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.perm-header h3 {
  margin: 0;
  font-size: 16px;
}

.perm-add {
  display: flex;
  align-items: center;
}

.icon-select-wrapper {
  display: inline-block;
  width:100%;
}
</style>