import {API} from '@/vue-plugins/api'
const forms = {}
const state = {
    form:{},
    globalIndex:0
}

import {upperFirst,camelCase} from 'lodash'
const requireComponentFormControls = require.context('@/components/form/controls', true, /\.vue$/)
let Controls = []
for (const file of requireComponentFormControls.keys()) {
  const componentConfig = requireComponentFormControls(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  
  Controls.push({
    control:'Ugo'+componentName,
    name:componentConfig.default.cname,
    corder:componentConfig.default.corder,
    icon:componentConfig.default.icon,
    properties:componentConfig.default.properties || [],
    initModel:componentConfig.default.initModel
  })
}
Controls.sort((a,b)=>(a.corder==undefined || a.corder>b.corder)?1:-1)

const getters = {
    form(state){
        return state.form
    },
    containers(state){
        return ['UgoRow','UgoSection','UgoTabs','UgoCol']
    },
    controls(state){
        return Controls
    },
    zzl_controls(state){
        const defs = ['UgoInput','UgoTextarea','UgoNumber','UgoDate','UgoCheck','UgoSection','UgoRadio','UgoZzlPreselect','UgoDatetime','UgoDatetimeRange','UgoSignature']
        return Controls.filter(v=>defs.includes(v.control))
    }

    
}

const actions = {
    get({commit},model){
        let form = forms[model]
        if(form){
            commit('save',form)
            return Promise.resolve(form)
        }else{
            return API.get(`models/${model}?view=edit`).then(res=>{
                let form = res.data.data
                form.model = model
                commit('save',form)
            })
        }
       
    },
    getByID({commit},id){
        return API.get(`forms/${id}`).then(res=>{
            let form = res.data.data
            commit('save',form)
        })
    },
    createGlobaID({commit,state}){
        commit('add_gid')
        return Promise.resolve(state.globalIndex)
    }
}

const mutations = {
    save(state,form){
        forms[form.model] = form
        state.form = form
    },
    add_gid(state){
        state.globalIndex = state.globalIndex++
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}